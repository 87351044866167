import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import ProductGrid from "../../components/ProductGrid";
import { ApiService } from "../../services/api.service";
import { ProductContext } from "../../context/prodContext";
import styled from "@emotion/styled";
import { Button, Card, Modal } from "react-bootstrap";
import { colours } from "../../styles/colours";
import { SubCatContext } from "../../context/subCategoryContext";
import { animateScroll as scroll } from "react-scroll";
import { AuthContext } from "../../context/authContext";
import { LoginContext } from "../../context/categoryContext";
import { SelectedCatContext } from "../../context/selectedCatContext";

import { Container, Row, Col, Form } from "react-bootstrap";
import { useCheckMobileScreen } from "../../helpers";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const S = {
  ProductBox: styled.div`
    font-size: 16px;
    height: 250px;
    max-width: 400px;
    min-width: 200px;
    border: #ccc 1px solid;
    img {
      width: 100%;
    }
    padding: 5px;
  `,
  ProductTitle: styled.div`
    font-size: 16px;
    padding: 5px;
    font-weight: 400;
    line-height: 16px;
    display: inline;
  `,
  ProductDescription: styled.div`
    font-size: 11px;
    padding: 5px;
    font-weight: 600;
  `,
  ProductPrice: styled.div`
    font-size: 18px;
    font-weight: 900;
    padding: 5px;
    text-align: right;
    color: ${colours.MARKANDY_RED};
  `,
  ProductImage: styled.div`
    margin-bottom: 10px;
  `,
  CategoryName: styled.div`
    font-size: 13px;
    font-weight: 200;
  `,
  Card: styled(Card)`
    box-shadow: 0 2px 4px #dadada;
    border-radius: 5px;
    min-height: 300px;
    margin-bottom: 20px;
    text-align: center;
    padding: 20% 0;
    padding-top: 50%;
    padding-right: 0px;
    padding-bottom: 20%;
    padding-left: 0px;
  `,
};

function Search(): ReactElement {
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);
  const { t, i18n } = useTranslation();
  const { userData } = useContext(AuthContext);

  const { productData, setProducts } = useContext(ProductContext);

  const { selectedCat, setSelectedCat } = useContext(SelectedCatContext);

  const [check, setCheck] = useState(0);
  const { categoryData } = useContext(LoginContext);
  const [subGuid, setSubGuid] = useState("");
  const { subCategoryData } = useContext(SubCatContext);
  const [loading, setLoading] = useState(false);
  const [topCategory, setTopCategory] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [categoryName, setCategoryName] = useState([] as any);

  const [loadProductType, setLoadProductType] = useState("");

  const [pageNumber, setPageNumber] = useState(1);

  const [subCategoryName, setSubCategoryName] = useState([] as any);

  const [pagination, setPagination] = useState([] as any);

  const [pageCount, setPageCount] = useState(0);

  const [productCount, setProductCount] = useState(0);
  const [keywordMessage, setKeywordMessage] = useState("");

  const [totalProductCount, setTotalProductCount] = useState(-1);

  const [totalRecords, setTotalRecords] = useState(0);

  const [categoryBoxes, setCategoryBoxes] = useState(true);

  const [categoryID, setCategoryID] = useState("");

  const [categoryGuid, setCategoryGuid] = useState("");

  const query = new URLSearchParams(useLocation().search);
  const id = query.get("p");
  const sid = query.get("s");
  const keyword = query.get("keyword");

  const findByKeyword = (e: any) => {
    //sanitize keyword search
    setCheck(0);

    setKeywordMessage("");
    setCategoryName("");
    setSubCategoryName("");
    setCategoryBoxes(false);

    setLoading(true);
    setKeywordMessage(e);
    setSearchKeyword(e);
    const apiService = new ApiService();
    const fetchProductByCategory = async () => {
      const response = await apiService.getProductsBySearch(e, pageNumber);
      setProducts(response);
      setTotalProductCount(response.length);
      setLoading(false);
      setKeywordMessage(searchKeyword);
    };
    fetchProductByCategory();
    // setSearchKeyword("");
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    console.log("FIRE2");
    setKeywordMessage("");
    setCheck(0);

    setCategoryName("");
    setSubCategoryName("");
    setCategoryBoxes(false);
    e.preventDefault();
    setTopCategory("");

    setLoading(true);
    setSelectedCat(null);

    //setSearchKeyword(e.target.value);
    const apiService = new ApiService();
    const fetchProductByCategory = async () => {
      const response = await apiService.getProductsBySearch(
        searchKeyword,
        pageNumber
      );
      setProducts(response);
      setTotalProductCount(response.length);
      setLoading(false);
      setKeywordMessage(searchKeyword);
    };
    fetchProductByCategory();
    //setSearchKeyword("");
  };

  const [pageNo, setPageNo] = useState(1);

  const itemsPerPage = 24;

  const fetchApps = async (e: string, pageNumber: number) => {
    setLoadProductType("Main");
    setCategoryBoxes(false);
    setCheck(0);
    var catFinder = findCategoryGuid(categoryData, e);
    var catID = catFinder?.categoryID;
    setTotalProductCount(-1);
    setKeywordMessage("");
    setCategoryGuid(catFinder.categoryGuid);

    setLoading(true);

    setSelectedCat(catFinder.categoryGuid.toUpperCase());

    setTopCategory(catFinder?.categoryGuid);

    setCategoryName("");
    setSubCategoryName("");

    let item = catID;

    if (!item) {
      setCategoryBoxes(false);
    }

    const apiService = new ApiService();
    const fetchProductByCategory = async () => {
      setLoadProductType("Main");

      const response = await apiService.getProductCategoryByCategoryPageNumber(
        parseInt(catID),
        pageNumber
      );
      var catFinder;
      if (e !== "") {
        catFinder = findCategoryGuid(categoryData, e);
      }

      setCategoryName(catFinder);

      setLoading(false);
      setProducts(response);
      setTotalProductCount(response.length);

      const moviesCount = response.length;
      setTotalRecords(moviesCount);
      if (moviesCount === 0) {
        setCategoryBoxes(false);
      }
    };
    fetchProductByCategory();
    //setSearchKeyword("");
  };

  const fetchSubApps = async (e: string, pageNumber: number) => {
    setLoadProductType("Sub");

    console.log("NAN MISSING " + e);

    setCheck(parseInt(e));

    setCategoryBoxes(false);
    var catFinder = findSubCategoryGuidByID(subCategoryData, e);

    var catID = catFinder?.subGuid;
    setTotalProductCount(-1);
    setKeywordMessage("");

    setLoading(true);

    //setCategoryName("");
    //setSubCategoryName("");

    let item = catID;

    if (!item) {
      setCategoryBoxes(false);
    }

    const apiService = new ApiService();
    const fetchProductBySubCategory = async () => {
      setLoadProductType("Sub");

      const response = await apiService.getProductSubCategoryBySubCategory(
        parseInt(e),
        pageNumber
      );
      var catFinder;
      if (e !== "") {
        catFinder = findSubCategoryGuid(subCategoryData, e);
      }

      //setTopCategory(catFinder?.categoryGuid);

      //setCategoryName(catFinder);

      setLoading(false);
      setProducts(response);
      setTotalProductCount(response.length);

      const moviesCount = response.length;
      setTotalRecords(moviesCount);
      if (moviesCount === 0) {
        setCategoryBoxes(false);
      }
    };
    fetchProductBySubCategory();

    //setSearchKeyword("");
  };

  const mobileScreen = useCheckMobileScreen();

  const getNextPageNumber = (e: number, productType: string) => {
    setCategoryBoxes(false);

    setPageNumber(pageNumber + 1);

    console.log("PROD TYPE " + productType);
    console.log("page number " + e);

    if (productType === "Main") {
      fetchApps(categoryGuid, e + 1);
    } else if (productType === "Sub") {
      fetchSubApps(subGuid, e + 1);
    } else if (productType === "Sub") {
      onSubmit(searchKeyword);
    } else {
      return;
    }

    //setPageNo(e);
    //fetchApps(categoryID);
  };

  useEffect(() => {
    // Scroll to the top after product data or page changes
    setTimeout(() => {
      scroll.scrollToTop({ smooth: true, duration: 100 });
    }, 50);
  }, [productData]); // Trigger scroll after product data updates

  const getPreviousPageNumber = (e: number, productType: string) => {
    setCategoryBoxes(false);

    setPageNumber(pageNumber - 1);

    if (productType === "Main") {
      fetchApps(categoryGuid, e - 1);
    } else if (productType === "Sub") {
      fetchSubApps(subGuid, e - 1);
    }

    //setPageNo(e);
    //fetchApps(categoryID);
  };

  const findCategoryName = (categoryData: any, categoryID: number) => {
    return categoryData.find((element: any) => {
      if (element) {
        return element.categoryID == categoryID;
      }
    });
  };

  const findCategoryGuid = (categoryData: any, categoryGuid: string) => {
    return categoryData.find((element: any) => {
      if (element) {
        return element.categoryGuid == categoryGuid.toLowerCase();
      }
    });
  };
  const findSubCategoryGuid = (
    subCategoryData: any,
    subCategoryGuid: string
  ) => {
    return subCategoryData.find((element: any) => {
      if (element) {
        return element.subGuid === subCategoryGuid.toLowerCase();
      }
    });
  };

  const findSubCategoryName = (subCategoryData: any, id: any) => {
    return subCategoryData.find((element: any) => {
      if (element) {
        return element.subID === parseInt(id);
      }
    });
  };

  const findSubCategoryNameByGuid = (subCategoryData: any, id: any) => {
    return subCategoryData.find((element: any) => {
      if (element) {
        return element.subGuid === id.toLowerCase();
      }
    });
  };

  const findSubCategoryGuidByID = (subCategoryData: any, id: any) => {
    return subCategoryData.find((element: any) => {
      if (element) {
        return element.subID === id;
      }
    });
  };

  const getValue = (e: any) => {
    setLoadProductType("Main");
    //setProducts([]);
    setPageNumber(1);
    // setSavedCategoryName(e);

    setTotalProductCount(-1);
    setKeywordMessage("");
    setCategoryBoxes(false);
    setCategoryName("");
    setSubCategoryName("");
    setLoading(true);
    setSearchKeyword("");

    setCategoryID(e);

    var catFinder = findCategoryName(categoryData, e);
    setCategoryName(catFinder);

    setTopCategory(catFinder.categoryGuid);
    setCategoryGuid(catFinder.categoryGuid);

    setSelectedCat(catFinder.categoryGuid.toUpperCase());

    setProductCount(catFinder.count);

    setPageCount(Math.ceil(productCount / itemsPerPage));

    createPagination();

    const id = e;

    const apiService = new ApiService();
    const fetchProductByCategory = async () => {
      const response = await apiService.getProductCategoryByCategoryPageNumber(
        id,
        1
        // itemsPerPage,
        // pageNo
      );
      setLoading(false);
      setProducts(response);
      setTotalProductCount(response.length);
    };
    fetchProductByCategory();
  };

  const loadSubProducts = (sid: any, pageNumber: number) => {
    setKeywordMessage("");
    setCategoryBoxes(false);
    setCategoryName("");
    setSubCategoryName("");
    setLoading(true);
    var catFinder = findSubCategoryGuid(subCategoryData, sid);
    var catID = catFinder.subID;

    const apiService = new ApiService();
    const fetchProductBySubGuid = async () => {
      const response = await apiService.getProductsBySubID(catID);
      setProducts(response);
      setTotalProductCount(response.length);
      setLoadProductType("Sub");

      setLoading(false);

      var catFinder3 = findSubCategoryNameByGuid(subCategoryData, sid);
      setSubCategoryName(catFinder3);

      var catFinder2 = findCategoryGuid(categoryData, catFinder3.categoryGuid);
      setCategoryName(catFinder2);

      setSelectedCat(catFinder2.categoryGuid.toUpperCase());

      setTopCategory(catFinder2?.categoryGuid);

      console.log("catfinder2 " + catFinder2);

      setCheck(parseInt(catFinder.subID));
    };
    fetchProductBySubGuid();
  };

  useEffect(() => {
    if (keyword !== null) {
      findByKeyword(keyword);
    }
    if (id !== null) {
      setPageNumber(1);

      setProducts([]);
      fetchApps(String(id), 1);
    } else if (sid !== null) {
      setPageNumber(1);
      loadSubProducts(sid, 1);
    } else {
      //fetchApps(String(selectedCat));
      //setTopCategory(selectedCat);
    }
  }, [id, sid, keyword]);

  const handleSubGuid = (e: any) => {
    setCheck(parseInt(e.target.name));

    setPageNumber(1);

    setLoadProductType("Sub");

    if (e.target.checked) {
      setLoading(true);
      setSubGuid(e.target.name);

      console.log("SET SUB GUID " + e.target.name);

      var catFinder = findSubCategoryName(subCategoryData, e.target.name);
      setSubCategoryName(catFinder);

      const apiService = new ApiService();
      const fetchProductBySubGuid = async () => {
        const response = await apiService.getProductsBySubID(e.target.name);
        setProducts(response);
        //setProducts([...response]);
        setTotalProductCount(response.length);
        setLoading(false);
      };
      fetchProductBySubGuid();
    }
  };

  const createPagination = () => {
    let menuItems = [];
    for (var i = 0; i < pageCount; i++) {
      menuItems.push(i);
    }
    setPagination(menuItems);
  };

  return (
    <div>
      <Container
        id="scrollable-container"
        ref={scrollableContainerRef}
        fluid
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",
          marginBottom: "-6px",
        }}
      ></Container>
      <Container
        fluid
        style={{ backgroundColor: "#f5f5f5", paddingTop: "15px" }}
      >
        <Container>
          <Row>
            <Col xs={12} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <div
                className="sidebar-widget price_range range mb-15"
                style={{ background: "#fff", marginTop: "15px" }}
              >
                <div className="widget-header position-relative mb-0 pb-5">
                  <h5 className="widget-title mb-10">Filter search results</h5>
                  <div className="bt-1 border-color-1"></div>
                </div>

                <div className="list-group">
                  <div className="list-group-item mb-10 mt-10">
                    <label className="fw-900">{t("Product Search")}</label>
                    <select
                      className="form-select mb-3"
                      aria-label="Default select example"
                      onChange={(e) => getValue(e.target.value)}
                    >
                      <option selected>
                        {categoryData ? "Select a machine" : "Loading"}
                      </option>

                      {categoryData &&
                        categoryData.map((item: any) =>
                          item.categoryID === 23 ? (
                            (userData.countryRef = "USA" && item.show === 2) ||
                            (userData.countryRef = "USA" && item.show === 1) ? (
                              <option
                                key={item.categoryID}
                                selected={
                                  selectedCat ===
                                  item.categoryGuid.toUpperCase()
                                }
                                value={item.categoryID}
                              >
                                {item.categoryName}
                              </option>
                            ) : (userData.countryref =
                                "Europe" && item.show === 1) ||
                              (userData.countryref =
                                "Europe" && item.show === 3) ? (
                              <option
                                selected={
                                  selectedCat ===
                                  item.categoryGuid.toUpperCase()
                                }
                                value={item.categoryID}
                              >
                                {item.categoryName}
                              </option>
                            ) : null
                          ) : item.show === 1 ? (
                            <option
                              selected={
                                selectedCat === item.categoryGuid.toUpperCase()
                              }
                              value={item.categoryID}
                            >
                              {item.categoryName}
                            </option>
                          ) : null
                        )}
                    </select>
                  </div>
                </div>

                <div className="list-groupke">
                  <div className="list-group-item mb-10 mt-10">
                    {subCategoryData &&
                      subCategoryData.map((item: any) =>
                        item.categoryGuid === topCategory && item.show === 1 ? (
                          <div
                            key={item.subID}
                            className="custom-checkbox-product"
                          >
                            <div>
                              <Form.Group
                                key={item.subID}
                                className="mb-0"
                                controlId={`formBasicCheckbox${item.subID}`}
                              >
                                <Form.Check
                                  type="radio"
                                  // label={item.subName + " (" + item.count + ")"}
                                  label={item.subName}
                                  name={item.subID}
                                  onChange={(e) => handleSubGuid(e)}
                                  checked={check === item.subID}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        ) : null
                      )}
                  </div>
                </div>
              </div>

              <div
                className="sidebar-widget price_range range mb-30"
                style={{ background: "#fff", marginTop: "15px" }}
              >
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Keyword Search</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter keyword"
                      value={searchKeyword}
                      onChange={(e) => setSearchKeyword(e.target.value)}
                      style={{ textAlign: "left", appearance: "none" }}
                    />
                    <Form.Text className="text-muted">
                      Type to search for an item
                    </Form.Text>
                  </Form.Group>
                  <button
                    onClick={onSubmit}
                    style={{ width: "100%" }}
                    className="btn btn-sm btn-fill-out btn-block hover-up"
                  >
                    <i className="fi-rs-search mr-5"></i> Search by keyword
                  </button>
                </Form>
              </div>
            </Col>

            <Col style={{ marginTop: "20px" }}>
              <div className="row">
                <div className="col-md-6">
                  {keywordMessage !== ""
                    ? "Search results for '" + keywordMessage + "'"
                    : ""}
                  <h2>{categoryName?.categoryName}</h2>
                  <p>{subCategoryName?.subName}</p>
                </div>
                <div
                  className="col-md-6 align-bottom"
                  style={{ textAlign: "right", bottom: "0" }}
                >
                  <p
                    className="small text-right align-bottom"
                    style={{ bottom: "0" }}
                  >
                    {/* Page {pageNo} of {Math.ceil(productCount / itemsPerPage)} */}
                    {/* {totalProductCount > 0
                      ? "Product Count: " + totalProductCount
                      : null} */}
                  </p>
                </div>
              </div>

              <hr></hr>

              {categoryBoxes ? (
                <React.Fragment>
                  <div className="row">
                    {categoryData &&
                      categoryData.map((item: any) =>
                        item.homepageShow ? (
                          <div
                            key={item.categoryID}
                            className="col-md-6 col-sm-12 col-lg-6 col-xl-3"
                          >
                            <div
                              onClick={(e) => getValue(item.categoryID)}
                              className="card-body-cat"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <p>{item.categoryName}</p>
                            </div>
                          </div>
                        ) : null
                      )}
                  </div>
                  <img
                    className="img-thumbnail"
                    onClick={(e) =>
                      getValue(
                        "8940CF82-6776-49B4-BC0E-8341854E4149".toLowerCase()
                      )
                    }
                    src={window.location.origin + `/imgs/tape-header2.jpg`}
                    width="100%"
                    style={{
                      cursor: "pointer",
                    }}
                    alt=""
                  />
                </React.Fragment>
              ) : null}

              {totalProductCount === 0 ? (
                <h2>No products match your search</h2>
              ) : null}
              <ProductGrid
                productData={productData}
                topCategory={topCategory}
                loading={loading}
              ></ProductGrid>
              <div
                className="row"
                style={{
                  flexDirection: "row",
                  marginBottom: "65px",
                  alignContent: "center",
                }}
              >
                <nav
                  aria-label="Page navigation example"
                  style={{
                    right: 0,
                  }}
                >
                  {productData.length > 0 && searchKeyword === "" ? (
                    <ul className="pagination">
                      {pageNumber > 1 ? (
                        <li
                          style={{ cursor: "pointer", marginRight: 1 }}
                          onClick={(e) =>
                            getPreviousPageNumber(pageNumber, loadProductType)
                          }
                          className="page-item"
                        >
                          <div className="page-link"> Previous</div>
                        </li>
                      ) : null}

                      {productData.length >= 18 ? (
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            getNextPageNumber(pageNumber, loadProductType)
                          }
                          className="page-item"
                        >
                          <div className="page-link"> Next</div>
                        </li>
                      ) : null}
                    </ul>
                  ) : null}

                  {/* <ul className="pagination">
                    {pagination &&
                      pagination.map((item: any, index: number) => (
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={(e) => getPageNumber(index + 1)}
                          className="page-item"
                        >
                          <span className="page-link">{index + 1}</span>
                        </li>
                      ))}

                     <li className="page-item">
                    <a className="page-link" href="#">
                      Previous
                    </a>
                  </li>

                  <li
                    style={{ cursor: "pointer" }}
                    onClick={(e) => getPageNumber(1)}
                    className="page-item"
                  >
                    <span className="page-link">1</span>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={(e) => getPageNumber(2)}
                    className="page-item"
                  >
                    <span className="page-link">2</span>
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={(e) => getPageNumber(3)}
                    className="page-item"
                  >
                    <span className="page-link">3</span>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      Next
                    </a>
                  </li> 
                </ul> */}
                </nav>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default Search;
