import React, { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { currencySelector, FormatDate } from "../../../helpers";
import { ApiService } from "../../../services/api.service";
function RecentCustomers(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [customers, setCustomers] = useState([]);

  const navigate = useNavigate();
  const apiService = new ApiService();

  function handleClick(item: any) {
    navigate("/Administration/CustomerEdit?id=" + item);
  }

  const fetchCustomers = async () => {
    const response = await apiService.getCustomerAll();
    setCustomers(response);
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Recent Customers</h3>
      <hr></hr>
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th scope="col">Ref</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {customers &&
            customers.slice(0, 10).map((item: any) => (
              <tr
                style={{ cursor: "pointer" }}
                onClick={() => handleClick(item.userGuid)}
              >
                <td style={{ width: "5%" }}>{item.userID}</td>
                <td>
                  {item.userFirstname} {item.userSurname}
                </td>
                <td>{item.userEmail}</td>

                <td>{item.userStatus}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
export default RecentCustomers;
