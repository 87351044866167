import React, { ReactElement, useState, useContext } from "react";
import Cookie from "js-cookie";
import { AdminContext } from "../../context/adminContext";
import { useNavigate } from "react-router";
import { CartContext } from "../../context/cartContext";
import { generateUUID } from "../../helpers";
import { setUser, getUser } from "../../services/auth.service";
import { Link } from "react-router-dom";

function AdminLogin(): ReactElement {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  let [authAccess] = useState("");
  let [hidden, setHidden] = useState("");

  const { guid, setCartGuid } = useContext(CartContext);

  const navigate = useNavigate();

  const { adminUserData, setAdminUserData } = useContext(AdminContext);

  const onSubmit = (e: any) => {
    e.preventDefault();

    fetch("api/blogUser/CreateToken", {
      method: "post",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        blogUserEmail: userEmail,
        blogUserPass: userPassword,
      }),
    })
      .then((Response) => Response.json())
      .then((result) => {
        Cookie.set("Admintoken", result.token);
        setAdminUserData(result.userGuid);

        navigate("/Administration/Dashboard");
      })
      .catch((error) => {
        setHidden("Invalid credentials");
      });
  };

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span></span> Admin Login
          </div>
        </div>
      </div>
      <section className="pt-50 pb-50">
        <div className="container ">
          <div className="row">
            <div className="col-lg-12 m-auto d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="col-lg-12 m-auto d-flex align-items-center justify-content-center">
                  <div className="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h3 className="pb-30">Administration Login</h3>
                        {hidden !== "" ? (
                          <h4 className="pb-30 error">{hidden}</h4>
                        ) : null}
                      </div>
                      <form name="loginForm" onSubmit={onSubmit}>
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            placeholder="Your Email"
                            onChange={(e) => setUserEmail(e.target.value)}
                          ></input>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={(e) => setUserPassword(e.target.value)}
                          ></input>
                        </div>

                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-fill-out btn-block hover-up"
                            name="login"
                          >
                            Log in
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AdminLogin;
