import React, { ReactElement } from "react";
import { Container } from "react-bootstrap";

interface PageBlockProps {
  text: string;
}

function PageBlock({ text }: PageBlockProps): ReactElement {
  return (
    <Container fluid>
      <div
        style={{
          color: "#333",
          height: "100vh",
          width: "50vw",
          textAlign: "center",
          paddingBottom: "150px",
        }}
      >
        <h1>{text}</h1>
        <img src="./imgs/loader2.gif" style={{ width: "200px" }} alt=""></img>
      </div>
    </Container>
  );
}

export default PageBlock;
