import React, { ReactElement, useContext } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { HighlightSpanKind } from "typescript";
import { AuthContext } from "../../context/authContext";
import Highlights from "../Highlights";
import MyAccount from "../My-Account";
import Popular from "../Popular";

function Dashboard(): ReactElement {
  const { userData } = useContext(AuthContext);
  return (
    <div>
      {userData ? (
        <div className="container" style={{ marginTop: "20px" }}>
          <div className="row">
            <div className="col-md-12" style={{ marginBottom: "0px" }}>
              <h2 style={{ paddingBottom: "10px" }}>
                Hello {userData.displayName}
              </h2>

              <h5 style={{ display: "block" }}>You are logged in. </h5>

              <hr></hr>
            </div>

            <div className="row">
              <div className="col-md-9">
                <img
                  src={window.location.origin + `/imgs/tesa.jpg`}
                  alt="text"
                  style={{ width: "100%", marginBottom: "20px" }}
                  className="bordered-image"
                ></img>
                <div className="row">
                  <div className="col-md-6">
                    <img
                      src={window.location.origin + `/imgs/banner-anilox.jpg`}
                      alt="text"
                      style={{ width: "100%", marginBottom: "20px" }}
                      className="bordered-image"
                    ></img>
                  </div>
                  <div className="col-md-6">
                    <img
                      src={
                        window.location.origin + `/imgs/banner-teknek-rolls.jpg`
                      }
                      alt="text"
                      style={{ width: "100%", marginBottom: "20px" }}
                      className="bordered-image"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <ul className="list-group" style={{ marginTop: "0px" }}>
                  {/* <Link to="../Dashboard">
                    <li className="list-group-item">My Account</li>
                  </Link> */}
                  <Link to="../Manuals">
                    {" "}
                    <li className="list-group-item">Manuals</li>
                  </Link>
                  <Link to="../Search">
                    {" "}
                    <li className="list-group-item">Product Search</li>
                  </Link>
                  <Link to="../Order-History">
                    {" "}
                    <li className="list-group-item">Order History</li>
                  </Link>
                  <Link to="../Edit-Account">
                    {" "}
                    <li className="list-group-item">Edit Account</li>
                  </Link>
                  <Link to="../Delivery-Addresses">
                    {" "}
                    <li className="list-group-item">Delivery Addresses</li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="row">
              <Highlights></Highlights>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default Dashboard;
