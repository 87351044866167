import React, { useState, createContext, useEffect } from "react";
import { ApiService } from "../services/api.service";
interface LoginProviderProps {
  children: React.ReactNode;
}

export const LoginContext = createContext({
  categoryData: [],
  setCategories: (loggedIn: []) => {},
});

export const LoginProvider = ({ children }: LoginProviderProps) => {
  const [categoryData, setCategories] = useState([]);

  const apiService = new ApiService();
  useEffect(() => {
    const fetchCategories = async () => {
      const response = await apiService.getCategories();

      // let sortedProducts = response.sort(
      //   (a: any, b: any) => parseFloat(a.catOrder) - parseFloat(b.catOrder)
      // );

      setCategories(response);
    };
    fetchCategories();
  }, []);

  return (
    <LoginContext.Provider value={{ categoryData, setCategories }}>
      {children}
    </LoginContext.Provider>
  );
};
