import React, { ReactElement } from "react";
import { Container } from "react-bootstrap";
function ButtonLoaderSmall(): ReactElement {
  return (
    <Container fluid>
      <div>
        <img src="./imgs/786.gif" style={{ width: "20px" }} alt=""></img>
      </div>
    </Container>
  );
}

export default ButtonLoaderSmall;
