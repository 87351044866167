import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

function Activate(): ReactElement {
  const query = new URLSearchParams(useLocation().search);
  const d = query.get("d");
  const e = query.get("e");
  const i = query.get("i");

  let [hidden, setHidden] = useState(0);
  let [error, setError] = useState(0);

  const activateUser = () => {
    fetch("api/activate/gr", {
      method: "post",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        userType: d ? "d" : e ? "e" : i ? "i" : null,
        userGuid: d ? d : e ? e : i ? i : null,
      }),
    })
      .then((Response) => Response.json())
      .then((result) => {
        setHidden(result);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    activateUser();
  }, []);

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb"></div>
        </div>
      </div>
      <section className="pt-50 pb-50">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-12 m-auto d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="col-lg-12 m-auto d-flex align-items-center justify-content-center">
                  <div className="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        {hidden === 1 ? <h3>User Activated</h3> : null}
                        {hidden === 2 ? <h3>User Already Activated</h3> : null}
                        {hidden === 0 ? (
                          <h3>User Could Not Be Activated</h3>
                        ) : null}
                        {error ? <h3>{error}</h3> : null}
                      </div>
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
export default Activate;
