import React, { ReactElement, useState, useEffect, useContext } from "react";
import { ApiService } from "../../services/api.service";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import { CountryContext } from "../../context/countriesContext";

interface DeliveryAddress {
  deliveryID: number;
  deliveryBuildingNo: string;
  deliveryStreet: string;
  deliveryPostalcode: string;
  deliveryRegion: string;
  countryID: number;
  userID: number;
  contactName: string;
}

function DeliveryAddressEdit(): ReactElement {
  const { countryData, setCountryData } = useContext(CountryContext);

  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress>({
    deliveryID: 0,
    deliveryBuildingNo: "",
    deliveryStreet: "",
    deliveryPostalcode: "",
    deliveryRegion: "",
    countryID: 0,
    userID: 0,
    contactName: "",
  });

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const apiService = new ApiService();

  const location = useLocation();
  const state = location.state as any;

  const fetchDeliveryAddress = async (e: number) => {
    //setLoading(true);
    const response = await apiService.getDeliveryAddressByID(e);
    setDeliveryAddress(response);
    setLoading(false);
  };

  const onSubmit = (data: any) => {
    PostAddress(data);
  };

  const PostAddress = async (data: any) => {
    data.deliveryID = state.id;
    data.countryID = deliveryAddress.countryID;
    data.Country = null;
    data.userID = deliveryAddress.userID;
    const response = await apiService.PostData(
      "api/deliveryAddress/SaveDeliveryAddress",
      data
    );
  };

  useEffect(() => {
    fetchDeliveryAddress(state.id);
  }, []);

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span></span> Delivery Addresses Edit
          </div>
        </div>
      </div>
      <section className="pt-10 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 m-auto">
              <div className="col-lg-12">
                <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-sm-12">
                          <label htmlFor="firstName" className="form-label">
                            Contact Name
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="productName"
                            placeholder="Contact Name"
                            defaultValue={deliveryAddress.contactName}
                            {...register("contactName", {
                              required: "contactName is required",
                            })}
                          ></input>
                          {errors.contactName ? (
                            <>
                              {errors.contactName.type === "required" && (
                                <span className="small error">
                                  {errors.contactName.message}
                                </span>
                              )}
                            </>
                          ) : null}
                        </div>

                        <div className="col-sm-12">
                          <label htmlFor="firstName" className="form-label">
                            Address Line 1
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="productName"
                            placeholder="Address Line 1"
                            defaultValue={deliveryAddress.deliveryBuildingNo}
                            {...register("deliveryBuildingNo", {
                              required: "deliveryBuildingNo is required",
                            })}
                          ></input>
                          {errors.deliveryBuildingNo ? (
                            <>
                              {errors.deliveryBuildingNo.type ===
                                "required" && (
                                <span className="small error">
                                  {errors.deliveryBuildingNo.message}
                                </span>
                              )}
                            </>
                          ) : null}
                        </div>

                        <div className="col-sm-12">
                          <label htmlFor="firstName" className="form-label">
                            Address Line 2
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="productName"
                            placeholder="Address Line 2"
                            defaultValue={deliveryAddress.deliveryStreet}
                            {...register("deliveryStreet", {
                              required: "deliveryStreet is required",
                            })}
                          ></input>
                          {errors.deliveryStreet ? (
                            <>
                              {errors.deliveryStreet.type === "required" && (
                                <span className="small error">
                                  {errors.deliveryStreet.message}
                                </span>
                              )}
                            </>
                          ) : null}
                        </div>

                        <div className="col-sm-12">
                          <label htmlFor="firstName" className="form-label">
                            Region
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="productName"
                            placeholder="Region"
                            defaultValue={deliveryAddress.deliveryRegion}
                            {...register("deliveryRegion", {
                              required: "deliveryRegion is required",
                            })}
                          ></input>
                          {errors.deliveryRegion ? (
                            <>
                              {errors.deliveryRegion.type === "required" && (
                                <span className="small error">
                                  {errors.deliveryRegion.message}
                                </span>
                              )}
                            </>
                          ) : null}
                        </div>

                        <div className="col-sm-12">
                          <label htmlFor="firstName" className="form-label">
                            Postcode/Zip
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            id="productName"
                            placeholder="Postcode/Zip"
                            defaultValue={deliveryAddress.deliveryPostalcode}
                            {...register("deliveryPostalcode", {
                              required: "deliveryPostalcode is required",
                            })}
                          ></input>
                          {errors.deliveryPostalcode ? (
                            <>
                              {errors.deliveryPostalcode.type ===
                                "required" && (
                                <span className="small error">
                                  {errors.deliveryPostalcode.message}
                                </span>
                              )}
                            </>
                          ) : null}
                        </div>

                        <div className="col-md-12">
                          <label htmlFor="country" className="form-label">
                            Country
                          </label>
                          <select
                            className="form-control select-active"
                            {...register("Country", { required: true })}
                          >
                            <option disabled value="">
                              Select country...
                            </option>
                            {countryData &&
                              countryData.map((item: any) => (
                                <option
                                  selected={
                                    deliveryAddress.countryID === item.countryID
                                  }
                                >
                                  {item.name}{" "}
                                </option>
                              ))}
                          </select>{" "}
                          {errors.Country ? (
                            <>
                              <span className="small error">
                                {errors.Country.message}
                              </span>
                            </>
                          ) : null}
                        </div>

                        <hr></hr>
                        <button className="btn btn-primary btn-user btn-block">
                          Save Address
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
export default DeliveryAddressEdit;
