import React, { ReactElement, useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { LoginContext } from "../../../context/categoryContext";
import { ApiService } from "../../../services/api.service";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { useForm } from "react-hook-form";
import DatePicker from "react-date-picker";
import { FormatDate, formatDateToISO } from "../../../helpers";

interface PromoCode {
  promoID: number;
  promoCode: string;
  promoName: string;
  promoStatus: number;
  promoValue: number;
  promoCont: string;
  dateStart: string;
  dateEnd: string;
}

function PromoCodeEdit(): ReactElement {
  const location = useLocation();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const navigate = useNavigate();

  const [value, onChange] = useState(new Date());

  function FormatDate(date: Date): string {
    if (isNaN(date.getTime())) {
      return ""; // Return empty string for invalid dates
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const [promoCode, setPromoCode] = useState<PromoCode>({
    promoID: 0,
    promoCode: "",
    promoName: "",
    promoStatus: 0,
    promoValue: 0,
    promoCont: "",
    dateStart: "",
    dateEnd: "",
  });

  useEffect(() => {
    if (promoCode) {
      // Set promoID if it exists; default to 0 if null
      setValue("promoID", promoCode.promoID ?? 0);

      if (promoCode.promoCode) {
        setValue("promoCode", promoCode.promoCode);
      }
      // Set other fields
      if (promoCode.dateStart) {
        setValue("dateStart", FormatDate(new Date(promoCode.dateStart)));
      }
      if (promoCode.dateEnd) {
        setValue("dateEnd", FormatDate(new Date(promoCode.dateEnd)));
      }
      if (promoCode.promoName) {
        setValue("promoName", promoCode.promoName);
      }
      if (promoCode.promoValue) {
        setValue("promoValue", promoCode.promoValue);
      }
      if (promoCode.promoCont) {
        setValue("promoCont", promoCode.promoCont);
      }
      if (promoCode.promoStatus) {
        setValue("promoStatus", promoCode.promoStatus);
      }
    }
  }, [promoCode, setValue]);

  const apiService = new ApiService();

  const fetchPromoCode = async (e: number) => {
    const response = await apiService.getPromoCodeAdmin(e);
    setPromoCode(response);
    var datey = new Date(response.dateStart);
    onChange(datey);
  };

  const onSubmit = (data: any) => {
    PostData(data);
  };

  const PostData = async (data: any) => {
    // Transform data before sending
    const transformedData = {
      promoID: promoCode.promoID,
      promoCode: data.promoCode,
      promoName: data.promoName,
      promoStatus: parseInt(data.promoStatus, 10) || null, // Parse promoStatus as integer
      promoValue: parseFloat(data.promoValue) || null, // Parse promoValue as float
      promoCont: data.promoCont,
      dateStart: formatDateToISO(data.dateStart), // Transform dateStart to ISO format
      dateEnd: formatDateToISO(data.dateEnd), // Transform dateEnd to ISO format
    };

    console.log("Transformed Data:", transformedData);

    // Send transformed data to the API
    const response = await apiService.PostData(
      "api/Promo/savePromoCode",
      transformedData
    );
    console.log(response);
    if (response === 1) {
      navigate("/Administration/Promo-Codes"); // Change "/promo-list" to your desired route
    }
  };

  useEffect(() => {
    fetchPromoCode(Number(id));
    reset();
  }, []);

  return (
    <div>
      <AdminHeader></AdminHeader>
      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">
                <b>{promoCode.promoName}</b>
              </h1>
            </div>

            <div className="row g-3">
              <div className="col-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-sm-12">
                    <label htmlFor="firstName" className="form-label">
                      Promo Code Name (for admin use)
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="productName"
                      placeholder="Promo Code Name"
                      defaultValue={promoCode.promoName}
                      {...register("promoName", {
                        required: "Promo Name is required",
                      })}
                    ></input>
                    {errors.promoName ? (
                      <>
                        {errors.promoName.type === "required" && (
                          <span className="small error">
                            {errors.promoName.message}
                          </span>
                        )}
                      </>
                    ) : null}
                  </div>

                  <div className="col-sm-12">
                    <label htmlFor="promoCode" className="form-label">
                      Promo Code (the code customers use (case sensitive!))
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="promoCode"
                      placeholder="Promo Code"
                      defaultValue={promoCode.promoCode}
                      {...register("promoCode", {
                        required: "Promo Code is required",
                      })}
                    ></input>
                    {errors.promoCode ? (
                      <>
                        {errors.promoCode.type === "required" && (
                          <span className="small error">
                            {errors.promoCode.message}
                          </span>
                        )}
                      </>
                    ) : null}
                  </div>

                  <div className="col-sm-12">
                    <label htmlFor="promoStatus" className="form-label">
                      Promo Code Status (1 = Live 0 = InActive)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="promoStatus"
                      placeholder="Product Code Status"
                      defaultValue={promoCode.promoStatus}
                      {...register("promoStatus", {})}
                    ></input>
                  </div>

                  <div className="col-sm-12">
                    <label htmlFor="promoCont" className="form-label">
                      Promo Code Continent (eg, Europe, USA, blank for all){" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="promoCont"
                      placeholder="Promo Code Continent"
                      defaultValue={promoCode.promoCont}
                      {...register("promoCont", {})}
                    ></input>
                  </div>

                  <div className="col-sm-12">
                    <label htmlFor="promoValue" className="form-label">
                      Promo Code Value (eg, 0.90 = 10%){" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="promoValue"
                      placeholder="Promo Code Value"
                      defaultValue={promoCode.promoValue}
                      {...register("promoValue", {})}
                    ></input>
                  </div>

                  <div className="col-sm-12">
                    <label htmlFor="lastName" className="form-label">
                      Promo Code Date Start (dd/mm/yyyy){" "}
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Date Start"
                      defaultValue={
                        promoCode.dateStart
                          ? FormatDate(new Date(promoCode.dateStart))
                          : ""
                      }
                      {...register("dateStart", {
                        required: "Start Date is required",
                      })}
                    ></input>
                    {errors.dateStart ? (
                      <>
                        {errors.dateStart.type === "required" && (
                          <span className="small error">
                            {errors.dateStart.message}
                          </span>
                        )}
                      </>
                    ) : null}
                  </div>

                  <div className="col-12">
                    <label htmlFor="username" className="form-label">
                      Promo Code Date End (dd/mm/yyyy){" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Date End"
                      defaultValue={
                        promoCode.dateEnd
                          ? FormatDate(new Date(promoCode.dateEnd))
                          : ""
                      }
                      {...register("dateEnd", {
                        required: "End Date is required",
                      })}
                    ></input>
                    {errors.dateEnd ? (
                      <>
                        {errors.dateEnd.type === "required" && (
                          <span className="small error">
                            {errors.dateEnd.message}
                          </span>
                        )}
                      </>
                    ) : null}
                  </div>

                  <hr></hr>
                  <button className="btn btn-primary btn-user btn-block">
                    Save Promo Code{" "}
                  </button>
                </form>
              </div>
            </div>
            <hr></hr>

            <hr></hr>
          </main>
        </div>
      </div>
    </div>
  );
}
export default PromoCodeEdit;
