import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import { ApiService } from "../../services/api.service";
import ButtonLoaderSmall from "../ButtonLoaderSmall";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/authContext";
import { Link } from "react-router-dom";
import Loader from "../Loader";

interface User {
  userID: number;
  userEmail: string;
  userFirstname: string;
  userSurname: string;
  userGuid: string;
  userHouseNo: string;
  userStreet: string;
  userPostcode: string;
  userCounty: string;
  userPass: string;
  userCity: string;
  userStatus: number;
  userSubscribe: boolean;
  Country: number;
  account: boolean;
  currency: number;
  partner: number;
  userTel: string;
  priceClassID: number;
  adminContact: number;
}

function EditAccount(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const userPass = useRef({});
  const [loading, setLoading] = useState(false);

  const { userData, setUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [countries, setCountries] = useState([] as any);
  const [user, setUser] = useState([] as any);

  const fetchAllCountries = async () => {
    const response = await apiService.getCountries();
    setCountries(response);
  };

  const [errorMessage, setErrorMessage] = useState("");

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const onSubmit = (data: any) => {
    // setLoading(true);
    PostData(data);
  };

  const apiService = new ApiService();

  const PostData = async (data: any) => {
    data.userPass = data.userPass2;
    const response = await apiService.PostData("api/User/SaveUser", data);
    setLoading(true);
    if (response === 2) {
      setErrorMessage(
        "A user with this email address already exists. Please reset your password."
      );
    } else if (response === 1) {
      fetchUser(String(userData.userGuid));
      navigate("/Account-Edited");
    }
    setLoading(false);
  };

  const fetchUser = async (e: string) => {
    setLoading(true);
    const response = await apiService.getUserByID(e);
    setUser(response);
    setUserData(response);
    reset(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchUser(String(userData.userGuid));
    fetchAllCountries();
    // reset(userData);
  }, []);

  useEffect(() => {
    if (userData) {
      reset({
        userFirstname: user.userFirstname,
        userSurname: user.userSurname,
        userStreet: user.userStreet,
        currency: user.currency,
        userPass: "",
        Country: user.Country,
      });
    }
  }, []);

  return (
    <div>
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/">Home</Link>
            </div>
          </div>
        </div>
        <section className="pt-10 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 m-auto">
                <div className="col-lg-12">
                  <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h3 className="mb-30">
                          Edit Your Mark Andy Parts Account
                        </h3>
                      </div>
                      <p className="mb-20 font-sm">
                        Your personal data will be used to support your
                        experience throughout this website, to manage access to
                        your account, and for other purposes described in our
                        privacy policy
                      </p>
                      {loading ? (
                        <Loader />
                      ) : (
                        <form
                          autoComplete="off"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="row">
                            {/* FIRST COL */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  id="form3Example4"
                                  {...register("userFirstname", {
                                    required: "First name is required",
                                  })}
                                />
                                {errors.userFirstname ? (
                                  <>
                                    {errors.userFirstname.type ===
                                      "required" && (
                                      <span className="small error">
                                        {errors.userFirstname.message}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="form3Example4"
                                  placeholder="Email Address"
                                  defaultValue={user.userEmail}
                                  {...register("userEmail", {
                                    required: "Email address is required",
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                      message: "Email is invalid",
                                    },
                                  })}
                                />
                                {errors.userEmail ? (
                                  <>
                                    {errors.userEmail.type === "required" && (
                                      <span className="small error">
                                        {errors.userEmail.message}
                                      </span>
                                    )}
                                    {errors.userEmail.type === "pattern" && (
                                      <span className="small error">
                                        {errors.userEmail.message}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  id="form3Example4"
                                  placeholder="Password"
                                  autoComplete="off"
                                  {...register("userPass2", {
                                    required: "Password is required",

                                    minLength: {
                                      value: 8,
                                      message:
                                        "Password must have at least 8 characters",
                                    },
                                  })}
                                />
                                {errors.userPass2 ? (
                                  <>
                                    {errors.userPass2.type === "required" && (
                                      <span className="small error">
                                        {errors.userPass2.message}
                                      </span>
                                    )}
                                    {errors.userPass2.type === "minLength" && (
                                      <span className="small error">
                                        {errors.userPass2.message}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <input
                                  type="text"
                                  id="form3Example4"
                                  placeholder="Address Line 1"
                                  {...register("userStreet", {
                                    maxLength: {
                                      value: 50,
                                      message: "Too many characters",
                                    },
                                  })}
                                />
                                {errors.userStreet ? (
                                  <>
                                    {errors.userStreet.type === "required" && (
                                      <span className="small error">
                                        {errors.userStreet.message}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <input
                                  type="text"
                                  id="form3Example4"
                                  placeholder="County"
                                  defaultValue={user.userCounty}
                                  {...register("userCounty", {
                                    maxLength: {
                                      value: 50,
                                      message: "Too many characters",
                                    },
                                  })}
                                />
                                {errors.userCounty ? (
                                  <>
                                    {errors.userCounty.type === "required" && (
                                      <span className="small error">
                                        {errors.userCounty.message}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <input
                                  type="text"
                                  id="form3Example4"
                                  placeholder="Telephone"
                                  defaultValue={user.userTel}
                                  {...register("userTel", {
                                    required: "Telephone number is required",
                                    pattern: {
                                      value: /^([0-9]\d*)(\.\d+)?$/,
                                      message: "Please enter only numbers",
                                    },
                                  })}
                                />
                                {errors.userTel ? (
                                  <>
                                    {errors.userTel.type === "required" && (
                                      <span className="small error">
                                        {errors.userTel.message}
                                      </span>
                                    )}
                                    {errors.userTel.type === "pattern" && (
                                      <span className="small error">
                                        {errors.userTel.message}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <select
                                  className="form-control select-active"
                                  {...register("currency", { required: true })}
                                >
                                  <option disabled value="">
                                    Select currency...
                                  </option>
                                  <option
                                    value={1}
                                    selected={1 === user.currency}
                                  >
                                    USD ($)
                                  </option>
                                  <option
                                    value={2}
                                    selected={2 === user.currency}
                                  >
                                    GBP (£)
                                  </option>
                                  <option
                                    value={3}
                                    selected={3 === user.currency}
                                  >
                                    EURO (€)
                                  </option>
                                </select>
                                {errors.currency ? (
                                  <>
                                    {errors.currency.type === "required" && (
                                      <span className="small error">
                                        {errors.currency.message}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>

                              <div className="login_footer form-group">
                                <div className="chek-form">
                                  <div className="custome-checkbox">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="checkbox"
                                      id="exampleCheckbox12"
                                      value=""
                                    ></input>
                                    {/* <label className="form-check-label">
                                    <span>
                                      I agree to terms &amp; conditions.
                                    </span>
                                  </label> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* SECOND COL */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="form3Example4"
                                  placeholder="Last Name"
                                  defaultValue={user.userSurname}
                                  {...register("userSurname", {
                                    required: "Last name is required",
                                  })}
                                />
                                {errors.userSurname ? (
                                  <>
                                    {errors.userSurname.type === "required" && (
                                      <span className="small error">
                                        {errors.userSurname.message}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="form3Example4"
                                  placeholder="Company Name"
                                  defaultValue={user.userHouseNo}
                                  {...register("userHouseNo", {
                                    required: "Company name is required",
                                  })}
                                />
                                {errors.userHouseNo ? (
                                  <>
                                    {errors.userHouseNo.type === "required" && (
                                      <span className="small error">
                                        {errors.userHouseNo.message}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  placeholder="Confirm Password"
                                  id="password"
                                  {...register("password_repeat", {
                                    required: "Please confirm your password  ",
                                    minLength: {
                                      value: 8,
                                      message:
                                        "Password must have at least 8 characters  ",
                                    },
                                    validate: (value) =>
                                      value === getValues("userPass2"),
                                  })}
                                />
                                {errors.password_repeat ? (
                                  <>
                                    {errors.password_repeat.type ===
                                      "required" && (
                                      <span className="small error">
                                        {errors.password_repeat.message}
                                      </span>
                                    )}
                                    {errors.password_repeat.type ===
                                      "minLength" && (
                                      <span className="small error">
                                        {errors.password_repeat.message}
                                      </span>
                                    )}
                                    {errors.password_repeat.type ===
                                      "validate" && (
                                      <span className="small error">
                                        Passwords do not match
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="form3Example4"
                                  placeholder="Address Line 2"
                                  defaultValue={user.userCity}
                                  {...register("userCity", {
                                    maxLength: {
                                      value: 50,
                                      message: "Too many characters",
                                    },
                                  })}
                                />
                                {errors.userCity ? (
                                  <>
                                    {errors.userCity.type === "required" && (
                                      <span className="small error">
                                        {errors.userCity.message}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="form3Example4"
                                  placeholder="Post Code / Zip"
                                  defaultValue={user.userPostcode}
                                  {...register("userPostcode", {
                                    maxLength: {
                                      value: 50,
                                      message: "Too many characters",
                                    },
                                  })}
                                />
                                {errors.userPostcode ? (
                                  <>
                                    {errors.userPostcode.type ===
                                      "maxLength" && (
                                      <span className="small error">
                                        {errors.userPostcode.message}
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>

                              <div className="form-group">
                                <div className="custom_select">
                                  <select
                                    className="form-control select-active"
                                    {...register("Country", { required: true })}
                                  >
                                    <option value="">Select country...</option>
                                    {countries &&
                                      countries.map((item: any) => (
                                        <option
                                          value={item.countryID}
                                          selected={
                                            item.countryID == user.country
                                          }
                                        >
                                          {item.name}{" "}
                                        </option>
                                      ))}
                                  </select>{" "}
                                  {errors.Country ? (
                                    <>
                                      <span className="small error">
                                        {errors.Country.message}
                                      </span>
                                    </>
                                  ) : null}
                                </div>
                              </div>

                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div style={{ display: "flex" }}>
                                      <input
                                        style={{
                                          width: "20px",
                                          paddingTop: "20px",
                                        }}
                                        type="checkbox"
                                        id="exampleCheckbox12"
                                        defaultChecked={user.userSubscribe}
                                        {...register("userSubscribe", {})}
                                        value=""
                                      ></input>
                                      <label
                                        style={{
                                          paddingLeft: "10px",
                                          paddingTop: "10px",
                                        }}
                                        className="form-check-label"
                                      >
                                        <span>Mailing List</span>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div style={{ display: "flex" }}>
                                      <input
                                        style={{
                                          width: "20px",
                                          paddingTop: "20px",
                                        }}
                                        type="checkbox"
                                        id="exampleCheckbox12"
                                        defaultChecked={user.account}
                                        {...register("account", {})}
                                        value=""
                                      ></input>
                                      <label
                                        style={{
                                          paddingLeft: "10px",
                                          paddingTop: "10px",
                                        }}
                                        className="form-check-label"
                                      >
                                        <span>Account Customer</span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr></hr>
                          </div>

                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-fill-out btn-block hover-up"
                              name="login"
                            >
                              {loading ? <ButtonLoaderSmall /> : null}

                              {loading ? "" : <span>Save</span>}
                            </button>
                            <hr></hr>
                            {errorMessage ? (
                              <h5 className="error">{errorMessage}</h5>
                            ) : null}
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
export default EditAccount;
