import React, { useState, createContext } from "react";

interface LoginProviderProps {
  children: React.ReactNode;
}

export const SearchContext = createContext([] as any);

export const SearchTextProvider = ({ children }: LoginProviderProps) => {
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchType, setSearchType] = useState([]);

  const value = {
    searchText,
    setSearchText,
    searchData,
    setSearchData,
    setSearchType,
    searchType,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};
