import React, { ReactElement } from "react";
import styled from "@emotion/styled";
import { colours } from "../../styles/colours";

const S = {
  Nav: styled.nav`
    color: ${colours.WHITE};
    a {
      font-weight: 500;
      color: ${colours.WHITE};
    }
    li {
      color: ${colours.WHITE};
      padding-right: 30px;
    }
  `,
};

function Navigation({ categoryData }: any): ReactElement {
  return <div></div>;
}

export default Navigation;
