import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";

function AdminNav(): ReactElement {
  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
    >
      <div className="position-sticky pt-3 sidebar-sticky">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className="nav-link active" to="/Administration/Dashboard">
              <span data-feather="home" className="align-text-bottom"></span>
              Dashboard
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Administration/Orders">
              <span data-feather="file" className="align-text-bottom"></span>
              Complete Orders
            </Link>
          </li>

          {/* <li className="nav-item">
            <Link className="nav-link" to="/Administration/Pending-Orders">
              <span data-feather="file" className="align-text-bottom"></span>
              Pending Orders
            </Link>
          </li> */}
          <hr></hr>
          <li className="nav-item">
            <Link className="nav-link" to="/Administration/Products">
              <span data-feather="file" className="align-text-bottom"></span>
              Products
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Administration/ProductVariant15">
              <span data-feather="file" className="align-text-bottom"></span>
              Product Pricing 15 Thou
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Administration/ProductVariant20">
              <span data-feather="file" className="align-text-bottom"></span>
              Product Pricing 20 Thou
            </Link>
          </li>
          <hr></hr>
          <li className="nav-item">
            <Link className="nav-link" to="/Administration/Categories">
              <span data-feather="file" className="align-text-bottom"></span>
              Categories
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link className="nav-link" to="/Administration/SubCategories">
              <span data-feather="file" className="align-text-bottom"></span>
              Sub Categories
            </Link>
          </li> */}
          <li className="nav-item">
            <Link className="nav-link" to="/Administration/Customers">
              <span data-feather="file" className="align-text-bottom"></span>
              Customers
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Administration/Stats">
              <span
                data-feather="bar-chart-2"
                className="align-text-bottom"
              ></span>
              Stats & Financials
            </Link>
          </li>

          <Link className="nav-link" to="/Administration/Media-Images">
            <span data-feather="file" className="align-text-bottom"></span>
            Media Images
          </Link>

          <Link className="nav-link" to="/Administration/Homepage-Images">
            <span data-feather="file" className="align-text-bottom"></span>
            Homepage Images
          </Link>
        </ul>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
          <span>Settings</span>
          <a className="link-secondary" href="#" aria-label="Add a new report">
            <span
              data-feather="plus-circle"
              className="align-text-bottom"
            ></span>
          </a>
        </h6>
        <ul className="nav flex-column mb-2">
          <li className="nav-item">
            <Link className="nav-link" to="/Administration/Promo-Codes">
              <span data-feather="file" className="align-text-bottom"></span>
              Promo Codes
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/Administration/Exchange-Rates">
              <span data-feather="file" className="align-text-bottom"></span>
              Exchange Rates
            </Link>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" href="#">
              <span
                data-feather="file-text"
                className="align-text-bottom"
              ></span>
              Categories
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <span
                data-feather="file-text"
                className="align-text-bottom"
              ></span>
              Distributors
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <span
                data-feather="file-text"
                className="align-text-bottom"
              ></span>
              Viewed Products
            </a>
          </li> */}
        </ul>
      </div>
    </nav>
  );
}
export default AdminNav;
