import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
function AccountEdited(): ReactElement {
  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span></span> Pages
            <span></span> Account Saved
          </div>
        </div>
      </div>
      <section className="pt-10 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 m-auto">
              <div className="col-lg-12">
                <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
                  <div className="padding_eight_all bg-white">
                    <div className="heading_s1">
                      <h3 className="mb-30">Your Account has been saved</h3>
                      <p>You can now return to your account dashboard.</p>
                      <br></br>
                      <Link to="/dashboard">Back to the your account</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
export default AccountEdited;
