import React, { useState, useEffect, ReactElement, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { ApiService } from "../../services/api.service";
import styled from "@emotion/styled";
import { colours } from "../../styles/colours";
import "../../assets/css/main.css";
import Loader from "../Loader";
import Related from "../Related";
import ProductSidebar from "../ProductSidebar";
import ProductFilter from "../ProductFilter";
import ProductCategory from "../ProductCategory";
import ProductTabs from "../ProductTabs";
import { AuthContext } from "../../context/authContext";
import { CartContext } from "../../context/cartContext";
import { Store } from "react-notifications-component";
import { Card, Col, Row } from "react-bootstrap";

import {
  CalculateTotal,
  FormatGeneralPrice,
  FormatBasePriceTest,
  toFixed,
  Truncate,
} from "../../helpers";
import { FormatMoney } from "../../helpers";
import { Link } from "react-router-dom";
import ButtonLoader from "../ButtonLoader/indes";
import Search from "../Search";
import Cookie from "js-cookie";
import PageBlock from "../PageBlock";
import { useNavigate } from "react-router";
import { ExchangeContext } from "../../context/exchangeContext";

interface Employee {
  productID: number;
  productName: string;
  productPriceDollar?: number;
  productDetails: string;
  award: number;
  stockLevel: number;
  useCloudImage: number;
  productPriceEuro?: number;
  ProductSuperVariantID?: number;
  productStatus: number;
}

interface QuickViewProps {
  prodID: number;
  prodName: string;
}

const S = {
  Button: styled.button`
    background-color: ${colours.MARKANDY_RED};
    color: ${colours.WHITE};
    border: 0px;
    font-size: 16px;
    padding: 12px;
    font-weight: 400;
    border-radius: 50px;
  `,
  ProductTitle: styled.div`
    font-size: 32px;
    padding: 5px;
    font-weight: 400;
    line-height: 16px;
    display: inline;
  `,

  ProductBox: styled.div`
    font-size: 16px;
    height: 250px;
    max-width: 400px;
    min-width: 200px;
    border: #ccc 1px solid;
    img {
      width: 100%;
    }
    padding: 5px;
  `,
  RelatedProductTitle: styled.div`
    font-size: 16px;
    padding: 5px;
    font-weight: 400;
    line-height: 16px;
    display: inline;
  `,
  ProductDescription: styled.div`
    font-size: 11px;
    padding: 5px;
    font-weight: 600;
  `,
  ProductPrice: styled.div`
    font-size: 18px;
    font-weight: 900;
    padding: 5px;
    text-align: right;
    color: ${colours.MARKANDY_RED};
  `,
  ProductImage: styled.div`
    margin-bottom: 10px;
  `,
  CategoryName: styled.div`
    font-size: 13px;
    font-weight: 200;
  `,
  Card: styled(Card)`
    box-shadow: 0 2px 4px #dadada;
    border-radius: 5px;
    min-height: 200px;
    margin-bottom: 20px;
    curser: pointer;
  `,
};

function QuickView({ prodName, prodID }: QuickViewProps): ReactElement {
  const query = new URLSearchParams(useLocation().search);
  const id = prodID;
  const name = prodName;

  const englandData = [
    { value: 1, name: "1" },
    { value: 2, name: "2" },
    { value: 3, name: "3" },
    { value: 4, name: "4" },
    { value: 5, name: "5" },
    { value: 6, name: "6" },
    { value: 7, name: "7" },
    { value: 8, name: "8" },
    { value: 9, name: "9" },
    { value: 10, name: "10" },
    { value: 11, name: "11" },
    { value: 12, name: "12" },
    { value: 13, name: "13" },
    { value: 14, name: "14" },
    { value: 15, name: "15" },
    { value: 16, name: "16" },
    { value: 17, name: "17" },
    { value: 18, name: "18" },
    { value: 19, name: "19" },
    { value: 20, name: "20" },
    { value: 21, name: "21" },
    { value: 22, name: "22" },
    { value: 23, name: "23" },
    { value: 24, name: "24" },
    { value: 25, name: "25" },
    { value: 26, name: "26" },
    { value: 27, name: "27" },
    { value: 28, name: "28" },
    { value: 29, name: "29" },
    { value: 30, name: "30" },
    { value: 31, name: "31" },
    { value: 32, name: "32" },
    { value: 33, name: "33" },
    { value: 34, name: "34" },
    { value: 35, name: "35" },
    { value: 36, name: "36" },
    { value: 37, name: "37" },
    { value: 38, name: "38" },
    { value: 39, name: "39" },
    { value: 40, name: "40" },
    { value: 41, name: "41" },
    { value: 42, name: "42" },
    { value: 43, name: "43" },
    { value: 44, name: "44" },
    { value: 45, name: "45" },
    { value: 46, name: "46" },
    { value: 47, name: "47" },
    { value: 48, name: "48" },
    { value: 49, name: "49" },
    { value: 50, name: "50" },
    { value: 51, name: "51" },
    { value: 52, name: "52" },
    { value: 53, name: "53" },
    { value: 54, name: "54" },
    { value: 55, name: "55" },
    { value: 56, name: "56" },
    { value: 57, name: "57" },
    { value: 58, name: "58" },
    { value: 59, name: "59" },
    { value: 60, name: "60" },
    { value: 61, name: "61" },
    { value: 62, name: "62" },
    { value: 63, name: "63" },
    { value: 64, name: "64" },
    { value: 65, name: "65" },
    { value: 66, name: "66" },
    { value: 67, name: "67" },
    { value: 68, name: "68" },
    { value: 69, name: "69" },
    { value: 70, name: "70" },
    { value: 71, name: "71" },
    { value: 72, name: "72" },
    { value: 73, name: "73" },
    { value: 74, name: "74" },
    { value: 75, name: "75" },
    { value: 76, name: "76" },
    { value: 77, name: "77" },
    { value: 78, name: "78" },
    { value: 79, name: "79" },
    { value: 80, name: "80" },
    { value: 81, name: "81" },
    { value: 82, name: "82" },
    { value: 83, name: "83" },
    { value: 84, name: "84" },
    { value: 85, name: "85" },
    { value: 86, name: "86" },
    { value: 87, name: "87" },
    { value: 88, name: "88" },
    { value: 89, name: "89" },
    { value: 90, name: "90" },
    { value: 91, name: "91" },
    { value: 92, name: "92" },
    { value: 93, name: "93" },
    { value: 94, name: "94" },
    { value: 95, name: "95" },
    { value: 96, name: "96" },
    { value: 97, name: "97" },
    { value: 98, name: "98" },
    { value: 99, name: "99" },
    { value: 100, name: "100" },
  ];

  const { userData, setUserData } = useContext(AuthContext);
  const { exchangeData } = useContext(ExchangeContext);
  // const [price, setPrice] = useState<any | null>(null);
  const [price, setPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [productQuantity, setProductQuantity] = useState(0);
  const [productVariantPrice, setProductVariantPrice] = useState(0);
  const [productVariantID, setProductVariantID] = useState(0);

  const [subProductVariantID, setSubProductVariantID] = useState(0);

  const [customVariant, setCustomVariant] = useState(false);
  const [productAvailable, setProductAvailable] = useState(true);

  const [USAUser, setUSAUser] = useState(false);

  const [addingToCart, setAddingToCart] = useState(false);
  const [hasRelated, setHasRelated] = useState(false);

  const { cart, setCart, guid } = useContext(CartContext);

  const [productVariant, setProductVariant] = useState([]);

  const [relatedData, setRelatedData] = useState([]);

  const [showVariantPriceOnload, setShowVariantPriceOnload] = useState(false);

  const [productData, setProduct] = useState<Employee>({
    productName: "",
    productID: 0,
    productPriceDollar: 0,
    productDetails: "",
    award: 0,
    stockLevel: 0,
    useCloudImage: 0,
    productPriceEuro: 0,
    ProductSuperVariantID: 0,
    productStatus: 0,
  });

  const [loading, setLoading] = useState(false);

  const apiService = new ApiService();

  const fetchRelatedData = async (e: number) => {
    const response = await apiService.getRelatedProducts(e);
    setRelatedData(response);
    if (response.length > 0) {
      setHasRelated(true);
    }
  };

  const fetchProductByID = async (e: number) => {
    setLoading(true);
    const response = await apiService.getProductByID(e);

    if (response.productStatus == 1) {
      setProductAvailable(true);
    }
    setPrice(
      FormatBasePriceTest(
        userData,
        exchangeData,
        response.productPriceEuro !== 0
          ? response.productPriceEuro
          : response.productPriceDollar
      )
    );
    setOriginalPrice(
      FormatBasePriceTest(
        userData,
        exchangeData,
        response.productPriceEuro !== 0
          ? response.productPriceEuro
          : response.productPriceDollar
      )
    );
    setProductID(response.productID);
    setProduct(response);
    setLoading(false);
    fetchProductVariantsByID(response.productSuperVariantID);
    //window.scrollTo(0, 0);

    if (
      userData.adminContact === 2 &&
      (response.award === 3 || response.award === 2)
    ) {
      setUSAUser(true);
    }
  };

  const fetchProductVariantsByID = async (e: number) => {
    const response = await apiService.getSubProductVariantBySuperID(e);
    setProductVariant(response);

    if (response.length > 0) {
      if (
        response[0].productVariantPriceGBP != null &&
        userData.currency === 2
      ) {
        setCustomVariant(true);
        setPrice(response[0].productVariantPriceGBP);
        setProductVariantPrice(response[0].productVariantPriceGBP);
      }
      if (
        response[0].productVariantPriceEuro != null &&
        userData.currency === 3
      ) {
        setCustomVariant(true);
        setPrice(response[0].productVariantPriceEuro);
        setProductVariantPrice(response[0].productVariantPriceEuro);
      } else if (userData.currency === 1) {
        setPrice(response[0].productVariantPriceDollar);
        setProductVariantPrice(response[0].productVariantPriceDollar);
      }
      response.length > 0 ? setVariant(true) : setVariant(false);
      //handlePrice(response[0].productVariantPriceDollar);
    }

    // window.scrollTo(0, 0);
  };

  const { setTotalPrice } = useContext(CartContext);

  const [quantity, setQuantity] = useState(1);
  const [variantPrice, setVariantPrice] = useState(0);

  const [isVariant, setVariant] = useState(false);

  const [productID, setProductID] = useState<any | null>(null);

  const [newCart, setNewCart] = useState<any | null>(null);

  const navigate = useNavigate();

  const onSubmit = (e: any) => {
    const cookieVal = Cookie.get("cart");

    let price = parseFloat(originalPrice.toFixed(2));

    setAddingToCart(true);
    e.preventDefault();

    apiService
      .createCartItem(
        productID,
        quantity,
        productVariantPrice ? productVariantPrice : price!,
        cookieVal!.toString(),
        productVariantID ? 1 : 0,
        subProductVariantID ? subProductVariantID : 99999999
      )
      .then((result) => {
        //setCart(result);

        // Store.addNotification({
        //   title: "Item added to cart",
        //   message: productID,
        //   type: "danger",
        //   insert: "top",
        //   container: "top-center",
        //   width: 500,
        //   dismiss: {
        //     duration: 1000,
        //     onScreen: true,
        //   },
        // });

        apiService.getCart(cookieVal!.toString()).then((result) => {
          setCart(result);
          setTotalPrice(CalculateTotal(result));
          setAddingToCart(false);
          navigate("/Basket/");
        });
      })
      .catch((error) => {
        setAddingToCart(false);
      });

    // fetch("api/cart/CreateCartItem", {
    //   method: "post",
    //   headers: {
    //     Accept: "application/json; charset=utf-8",
    //     "Content-Type": "application/json;charset=UTF-8",
    //   },
    //   body: JSON.stringify({
    //     productID: id,
    //     productQuantity: quantity,
    //     price: price.toFixed(2),
    //     GUID: "F20DC29A-2C08-481D-88C0-577A9E478C8A",
    //   }),
    // })
    //   .then((Response) => Response.json())
    //   .then((result) => {
    //     setCart(result);
    //     apiService
    //       .getCart("F20DC29A-2C08-481D-88C0-577A9E478C8A")
    //       .then((result) => {
    //         setCart(result);
    //       });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const handleQty = (e: any) => {
    setQuantity(e.target.value);

    let price = parseFloat(originalPrice.toFixed(2));

    if (isVariant) {
      setPrice(
        productVariantPrice != undefined
          ? productVariantPrice * e.target.value
          : 0
      );
    } else {
      if (productData?.productPriceEuro !== 0) {
        setPrice(
          productData?.productPriceEuro != undefined
            ? price * e.target.value
            : 0
        );
      } else {
        setPrice(
          productData?.productPriceDollar != undefined
            ? price * e.target.value
            : 0
        );
      }
    }
  };

  const handlePrice = (e: any, quantity: any) => {
    setSubProductVariantID(e.target.selectedIndex + 1);
    setProductVariantID(e.target.selectedIndex + 1);
    setPrice(e.target.value * quantity);
    setProductVariantPrice(e.target.value);
  };

  useEffect(() => {
    if (exchangeData) {
      fetchProductByID(Number(id));
      fetchRelatedData(Number(id));
    }
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {addingToCart ? (
            <div
              style={{
                position: "absolute",
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                paddingTop: "80px",
                width: "50vw",
              }}
            >
              {/* <h1>Adding to basket</h1>
                  <img
                    src="./imgs/loader2.gif"
                    style={{ width: "200px" }}
                    alt=""
                  ></img> */}
              <PageBlock text="Adding to basket"></PageBlock>
            </div>
          ) : null}
          {/* <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          > */}

          {productAvailable ? (
            <main className="main">
              {/* <div className="page-header breadcrumb-wrap">
              <div className="container">
                <Breadcrumb></Breadcrumb>
              </div>
            </div> */}

              <section className={`${addingToCart ? "disabled" : null} `}>
                <div className="container">
                  <div className="row flex-row-reverse">
                    <div className="col-lg-12">
                      <div className="product-detail accordion-detail">
                        <div className="row mb-50">
                          <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="detail-gallery">
                              {/* <span className="zoom-icon">
                                <i className="fi-rs-search"></i>
                              </span> */}
                              <div className="product-image-slider">
                                {productData.useCloudImage === 1 ? (
                                  <figure className="border-radius-10">
                                    <img
                                      src={`https://res.cloudinary.com/cloud2mad/image/upload/c_scale,l_jluhalsdbrro7zqthzv3,w_421/v1678617512/${productData.productName}.jpg`}
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "#ccc 1px solid",
                                        borderRadius: "5px",
                                      }}
                                      alt="text"
                                    ></img>
                                  </figure>
                                ) : (
                                  <figure className="border-radius-10">
                                    <img
                                      src={
                                        window.location.origin +
                                        `/Admin/Catalogue/${productData.productName}.jpg`
                                      }
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        border: "#ccc 1px solid",
                                        borderRadius: "5px",
                                      }}
                                      alt="text"
                                    ></img>
                                  </figure>
                                )}
                              </div>
                            </div>
                            <div className="social-icons single-share d-none d-sm-block">
                              {hasRelated ? (
                                <Row style={{ marginTop: "15px" }}>
                                  <h4>Related Products</h4>
                                  <hr></hr>
                                  {relatedData &&
                                    relatedData.map((item: any) => (
                                      <Col
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        xxl={6}
                                        //key={item.productID}
                                      >
                                        <S.Card
                                          onClick={() =>
                                            fetchProductByID(
                                              item.relatedProductID
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <div className="card-body">
                                            <S.ProductImage>
                                              <img
                                                className="card-img-top"
                                                src={
                                                  window.location.origin +
                                                  `/Admin/Catalogue/${item.productName}.jpg`
                                                }
                                                style={{
                                                  maxHeight: "70vw",
                                                  minHeight: "15vw",
                                                  objectFit: "cover",
                                                  width: "100%",
                                                }}
                                                alt=""
                                              ></img>
                                            </S.ProductImage>
                                            <S.RelatedProductTitle className="card-title">
                                              {" "}
                                              {item.productName}
                                            </S.RelatedProductTitle>
                                            <S.ProductDescription>
                                              {Truncate(item.productDetails!)}
                                            </S.ProductDescription>
                                          </div>
                                        </S.Card>
                                      </Col>
                                    ))}
                                </Row>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="detail-info">
                              <h2 className="title-detail">
                                {productData.productName}
                              </h2>
                              <div className="product-detail-rating">
                                <div className="pro-details-brand">
                                  <span>
                                    {" "}
                                    Brands: <a>Mark Andy</a>
                                  </span>
                                </div>
                                {/* <div className="product-rate-cover text-end">
                                <div className="product-rate d-inline-block">
                                  <div
                                    className="product-rating"
                                    style={{ width: "90%" }}
                                  ></div>
                                </div>
                                <span className="font-small ml-5 text-muted">
                                  {" "}
                                  (25 reviews)
                                </span>
                              </div> */}
                              </div>
                              <div className="clearfix product-price-cover">
                                <div className="product-price primary-color float-left">
                                  <ins>
                                    <span className="text-brand">
                                      {userData.adminContact === 2 &&
                                      productData.award === 3 ? (
                                        <p>
                                          To order Tesa Tape in your country,
                                          please visit shop.markandy.com
                                        </p>
                                      ) : (userData.adminContact === 2 &&
                                          productData.award) === 1 ? (
                                        <p>
                                          This product/offer is currently
                                          unavailable in your country
                                        </p>
                                      ) : customVariant ? (
                                        FormatGeneralPrice(price!)
                                      ) : price !== undefined ? (
                                        FormatGeneralPrice(price!)
                                      ) : null}
                                    </span>
                                  </ins>
                                  <ins>
                                    {/* <span className="old-price font-md ml-15">
                                    $200.00
                                  </span> */}
                                  </ins>
                                  {/* <span className="save-price  font-md color3 ml-15">
                                  25% Off
                                </span> */}
                                </div>
                              </div>

                              {productVariant.length > 0 && !USAUser ? (
                                <React.Fragment>
                                  <label style={{ paddingTop: "10px" }}>
                                    Select Variant Width
                                  </label>
                                  <select
                                    className="form-control mt-0"
                                    style={{ width: "100%" }}
                                    onChange={(e) => handlePrice(e, quantity)}
                                  >
                                    {/* <option value="0">
                                  --- Select a product variant ---
                                </option> */}

                                    {productVariant &&
                                      productVariant.map(
                                        (item: any, index: number) => (
                                          <option
                                            key={item.subProductVariantID}
                                            value={
                                              customVariant &&
                                              userData.currency === 2
                                                ? item.productVariantPriceGBP
                                                : customVariant &&
                                                  userData.currency === 3
                                                ? item.productVariantPriceEuro
                                                : item.productVariantPriceDollar
                                            }
                                            selected={index === 0}
                                          >
                                            {item.productVariantDetails}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </React.Fragment>
                              ) : null}

                              <div className="bt-1 border-color-1 mt-15 mb-15"></div>
                              <div className="short-desc mb-30">
                                {<p>{productData.productDetails}</p>}
                              </div>
                              <div className="product_sort_info font-xs mb-30">
                                {/* <ul>
                                <li className="mb-10">
                                  <i className="fi-rs-crown mr-5"></i> 1 Year AL
                                  Jazeera Brand Warranty
                                </li>
                                <li className="mb-10">
                                  <i className="fi-rs-refresh mr-5"></i> 30 Day
                                  Return Policy
                                </li>
                                <li>
                                  <i className="fi-rs-credit-card mr-5"></i>{" "}
                                  Cash on Delivery available
                                </li>
                              </ul> */}
                              </div>
                              <div className="attr-detail attr-color mb-15"></div>
                              <div className="attr-detail attr-size"></div>
                              <div className="bt-1 border-color-1 mt-30 mb-30"></div>

                              {userData.userGuid ? (
                                !USAUser ? (
                                  <div
                                    className="detail-extralink"
                                    style={{ display: "flex" }}
                                  >
                                    <label
                                      style={{
                                        lineHeight: "50px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      {" "}
                                      Quantity
                                    </label>
                                    <select
                                      className="form-control"
                                      style={{
                                        width: "50px",
                                        cursor: "pointer",
                                      }}
                                      onChange={(e) => handleQty(e)}
                                    >
                                      {englandData?.map((e: any, key: any) => {
                                        return (
                                          <option key={key} value={e.value}>
                                            {" "}
                                            {e.name}
                                          </option>
                                        );
                                      })}
                                    </select>

                                    <div className="product-extra-link2">
                                      <button
                                        type="submit"
                                        onClick={(e) => onSubmit(e)}
                                        className="button button-add-to-cart"
                                      >
                                        {addingToCart ? (
                                          <ButtonLoader />
                                        ) : (
                                          <span>Add to cart</span>
                                        )}
                                      </button>

                                      {cart.length > 0 ? (
                                        <Link
                                          style={{
                                            width: "100px",
                                            border: "none",
                                          }}
                                          to="/basket"
                                        >
                                          Go to basket{" "}
                                        </Link>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null
                              ) : (
                                <Link
                                  className="btn"
                                  to={`/LogIn?returnUrl=true&id=${productData.productID}&name=${productData.productName}`}
                                >
                                  <i className="fi-rs-box-alt mr-10"></i> Log in
                                  or create an account to view prices
                                </Link>
                              )}
                              <ul className="product-meta font-xs color-grey mt-50">
                                <li className="mb-5">
                                  SKU: {productData.productName}
                                </li>

                                {productData.stockLevel > 0 ? (
                                  <li>
                                    Availability:
                                    <span className="in-stock text-success ml-5">
                                      <span>Item In Stock</span>
                                    </span>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          ) : (
            <main className="main">
              <h2>This product is not available</h2>
            </main>
          )}
          {/* </motion.div> */}
        </div>
      )}
    </div>
  );
}

export default QuickView;
