import React, { ReactElement } from "react";

function Footer(): ReactElement {
  const d = new Date();
  let year = d.getFullYear();

  return (
    <div className="container pb-20 mt-50 wow fadeIn animated">
      <div className="row">
        <div className="col-12 mb-20">
          <div className="footer-bottom"></div>
        </div>
        <div className="col-lg-6">
          <p className="float-md-left font-sm text-muted mb-0">
            <strong className="text-brand">Mark Andy</strong> &copy; {year}
          </p>
        </div>
        <div className="col-lg-6">
          <p className="text-lg-end text-start font-sm text-muted mb-0">
            All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
}
export default Footer;
