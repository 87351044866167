import React, { useState, createContext } from "react";

interface LoginProviderProps {
  children: React.ReactNode;
}

export const ProductContext = createContext([] as any);

export const ProductProvider = ({ children }: LoginProviderProps) => {
  const [productData, setProducts] = useState([] as any);

  return (
    <ProductContext.Provider value={{ productData, setProducts }}>
      {children}
    </ProductContext.Provider>
  );
};
