import React, { useState, ReactElement, useContext } from "react";
import styled from "@emotion/styled";
import { colours } from "../../styles/colours";
import { Row, Col, Modal, Button } from "react-bootstrap";
import Loader from "../Loader";
import ProductGridItem from "./ProductGridItem";
import { Link } from "react-router-dom";
import QuickView from "../QuickView";

const S = {
  Typography: styled.h1`
    font-size: 18px;
    margin-top: 10px;
    color: ${colours.DARK_GREY};
    font-weight: 700;
    @media (min-width: 576px) {
      font-size: 20px;
    }
    @media (min-width: 992px) {
      margin-top: 0px;
      font-size: 30px;
    }
  `,
  ProductItemTitle: styled.h4`
    font-size: 12px;
    font-weight: 800;
    color: ${colours.DARK_GREY};
  `,
  PageHeadWrapper: styled.div`
    margin-top: 20px;
    margin-bottom: 0px;
    float: left;
    height: 50px;
    .form-select {
      margin-right: 10px;
      font-family: "Roboto";
    }
  `,

  SearchWrapper: styled.div`
    float: right;
    margin-top: 40px;
    margin-bottom: 40px;
  `,
  Button: styled.button`
    background-color: ${colours.MARKANDY_RED};
    color: ${colours.WHITE};
    border: 0px;
    font-size: 12px;
    padding: 12px;
    font-weight: 900;
  `,
  ButtonContainer: styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  `,
  Box: styled.div`
    left: 0px;
    position: absolute;
    top: 155px;
    background-color: #ccc;
    color: #444;
    z-index: 2;
    height: 100vh;
    height: 50vw;
  `,
  LinkStyle: styled.div`
    background-color: #fff;
    cursor: pointer;
    /* &:hover .card-body {
      background-color: #333;
      color: white;
      //border: ${colours.MARKANDY_RED} 1px solid;
      cursor:'pointer'
    } */
  `,
};

function ProductGrid({
  catData,
  productData,
  loading,
  topCategory,
}: any): ReactElement {
  const prodData = productData;

  const [show, setShow] = useState(false);
  const [selectedProdID, setSelectedProdID] = useState(0);
  const [selectedProdName, setSelectedProdName] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (prodID: number, prodName: string) => {
    setSelectedProdID(prodID);
    setSelectedProdName(prodName);
    setShow(true);
  };

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <div className="App-header">
        <div className="clearfix"></div>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <Row style={{ marginTop: "15px" }}>
              {prodData &&
                prodData.map((item: any) => (
                  <Col
                    xs={12}
                    md={6}
                    lg={4}
                    xl={4}
                    xxl={4}
                    key={item.productID}
                  >
                    {" "}
                    <S.LinkStyle>
                      <div
                        onClick={(e) =>
                          handleShow(item.productID, item.productName)
                        }

                        // style={{ textDecoration: "none" }}
                        // to={`/product?id=${item.productID}&name=${item.productName}`}
                      >
                        <S.ProductItemTitle>
                          <ProductGridItem
                            key={item.productID}
                            name={item.productName}
                            price={item.productPriceDollar}
                            description={item.productDetails}
                            category={item.category?.categoryName}
                            productIntro={item.productIntro}
                            flash={item.flash}
                            award={item.award}
                            topCategory={topCategory}
                            useCloudImage={item.useCloudImage}
                          ></ProductGridItem>
                        </S.ProductItemTitle>
                      </div>
                    </S.LinkStyle>
                  </Col>
                ))}
            </Row>
          </div>
        )}
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        style={{ width: "100vw" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <img
              src="https://parts.markandy.com/img/mark_logo-primary.svg"
              style={{ width: "40%", paddingTop: "20px", paddingLeft: "20px" }}
              alt="text"
            ></img>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuickView
            prodName={selectedProdName}
            prodID={selectedProdID}
          ></QuickView>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProductGrid;
