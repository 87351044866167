import React, { useState, createContext, useEffect } from "react";
import { ApiService } from "../services/api.service";

interface ExchangeProviderProps {
  children: React.ReactNode;
}

export const ExchangeContext = createContext([] as any);

export const ExchangeProvider = ({ children }: ExchangeProviderProps) => {
  const [exchangeData, setExchangeData] = useState([] as any);

  const apiService = new ApiService();
  useEffect(() => {
    const fetchExchange = async () => {
      const response = await apiService.getExchangeRates();
      setExchangeData(response);
    };
    fetchExchange();
  }, []);

  return (
    <ExchangeContext.Provider value={{ exchangeData, setExchangeData }}>
      {children}
    </ExchangeContext.Provider>
  );
};
