import React, { ReactElement, useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ApiService } from "../../services/api.service";
import ButtonLoaderSmall from "../ButtonLoaderSmall";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

interface User {
  userID: number;
  userEmail: string;
  userFirstname: string;
  userSurname: string;
  userGuid: string;
  userHouseNo: string;
  userStreet: string;
  userPostcode: string;
  userCounty: string;
  userPass: string;
  userCity: string;
  userStatus: number;
  userSubscribe: boolean;
  Country: number;
  account: boolean;
  currency: number;
  partner: number;
  userTel: string;
  priceClassID: number;
  adminContact: number;
}

function CreateAccount(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const userPass = useRef({});

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [countries, setCountries] = useState([] as any);

  const fetchAllCountries = async () => {
    const response = await apiService.getCountries();
    setCountries(response);
  };

  const [errorMessage, setErrorMessage] = useState("");

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const onSubmit = (data: any) => {
    setLoading(true);
    PostData(data);
  };

  const apiService = new ApiService();

  const PostData = async (data: any) => {
    const response = await apiService.PostData("api/User/AddUser", data);
    setLoading(true);
    if (response === 2) {
      setErrorMessage(
        "A user with this email address already exists. Please reset your password."
      );
    } else if (response === 1) {
      navigate("/Account-Created");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllCountries();
  }, []);

  return (
    <div>
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <Link to="/">Home</Link>
            </div>
          </div>
        </div>
        <section className="pt-10 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 m-auto">
                <div className="col-lg-12">
                  <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h3 className="mb-30">
                          Create a Mark Andy Parts Account
                        </h3>
                      </div>
                      <p className="mb-20 font-sm">
                        Your personal data will be used to support your
                        experience throughout this website, to manage access to
                        your account, and for other purposes described in our
                        privacy policy
                      </p>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          {/* FIRST COL */}
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                tabIndex={1}
                                placeholder="First Name"
                                id="form3Example4"
                                {...register("userFirstname", {
                                  required: "First name is required",
                                })}
                              />
                              {errors.userFirstname ? (
                                <>
                                  {errors.userFirstname.type === "required" && (
                                    <span className="small error">
                                      {errors.userFirstname.message}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <input
                                tabIndex={3}
                                type="text"
                                id="form3Example4"
                                placeholder="Email Address"
                                {...register("userEmail", {
                                  required: "Email address is required",
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Email is invalid",
                                  },
                                })}
                              />
                              {errors.userEmail ? (
                                <>
                                  {errors.userEmail.type === "required" && (
                                    <span className="small error">
                                      {errors.userEmail.message}
                                    </span>
                                  )}
                                  {errors.userEmail.type === "pattern" && (
                                    <span className="small error">
                                      {errors.userEmail.message}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <input
                                tabIndex={5}
                                type="password"
                                id="form3Example4"
                                placeholder="Password"
                                {...register("userPass", {
                                  required: "Password is required",
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password must have at least 8 characters",
                                  },
                                })}
                              />
                              {errors.userPass ? (
                                <>
                                  {errors.userPass.type === "required" && (
                                    <span className="small error">
                                      {errors.userPass.message}
                                    </span>
                                  )}
                                  {errors.userPass.type === "minLength" && (
                                    <span className="small error">
                                      {errors.userPass.message}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <input
                                type="text"
                                tabIndex={7}
                                id="form3Example4"
                                placeholder="Address Line 1"
                                {...register("userStreet", {
                                  maxLength: {
                                    value: 50,
                                    message: "Too many characters",
                                  },
                                })}
                              />
                              {errors.userStreet ? (
                                <>
                                  {errors.userStreet.type === "required" && (
                                    <span className="small error">
                                      {errors.userStreet.message}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <input
                                type="text"
                                tabIndex={9}
                                id="form3Example4"
                                placeholder="County"
                                {...register("userCounty", {
                                  maxLength: {
                                    value: 50,
                                    message: "Too many characters",
                                  },
                                })}
                              />
                              {errors.userCounty ? (
                                <>
                                  {errors.userCounty.type === "required" && (
                                    <span className="small error">
                                      {errors.userCounty.message}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <input
                                type="text"
                                tabIndex={10}
                                id="form3Example4"
                                placeholder="Telephone"
                                {...register("userTel", {
                                  required: "Telephone number is required",
                                  pattern: {
                                    value: /^([0-9]\d*)(\.\d+)?$/,
                                    message: "Please enter only numbers",
                                  },
                                })}
                              />
                              {errors.userTel ? (
                                <>
                                  {errors.userTel.type === "required" && (
                                    <span className="small error">
                                      {errors.userTel.message}
                                    </span>
                                  )}
                                  {errors.userTel.type === "pattern" && (
                                    <span className="small error">
                                      {errors.userTel.message}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <select
                                className="form-control select-active"
                                {...register("currency", {
                                  required: "Select a currency",
                                })}
                                tabIndex={11}
                              >
                                <option value="">Preferred Currency</option>

                                <option value={1}>USD ($)</option>

                                <option value={2}>GBP (£)</option>

                                <option value={3}>Eur (€)</option>
                              </select>
                              {errors.currency ? (
                                <>
                                  <span className="small error">
                                    {errors.currency.message}
                                  </span>
                                </>
                              ) : null}
                            </div>

                            <div className="login_footer form-group">
                              <div className="chek-form">
                                <div className="custome-checkbox">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="checkbox"
                                    id="exampleCheckbox12"
                                    value=""
                                  ></input>
                                  {/* <label className="form-check-label">
                                    <span>
                                      I agree to terms &amp; conditions.
                                    </span>
                                  </label> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* SECOND COL */}
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                tabIndex={2}
                                type="text"
                                id="form3Example4"
                                placeholder="Last Name"
                                {...register("userSurname", {
                                  required: "Last name is required",
                                })}
                              />
                              {errors.userSurname ? (
                                <>
                                  {errors.userSurname.type === "required" && (
                                    <span className="small error">
                                      {errors.userSurname.message}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <input
                                tabIndex={4}
                                type="text"
                                id="form3Example4"
                                placeholder="Company Name"
                                {...register("userHouseNo", {
                                  required: "Company name is required",
                                })}
                              />
                              {errors.userHouseNo ? (
                                <>
                                  {errors.userHouseNo.type === "required" && (
                                    <span className="small error">
                                      {errors.userHouseNo.message}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                tabIndex={6}
                                placeholder="Confirm Password"
                                id="password"
                                {...register("password_repeat", {
                                  required: "Please confirm your password  ",
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password must have at least 8 characters  ",
                                  },
                                  validate: (value) =>
                                    value === getValues("userPass"),
                                })}
                              />
                              {errors.password_repeat ? (
                                <>
                                  {errors.password_repeat.type ===
                                    "required" && (
                                    <span className="small error">
                                      {errors.password_repeat.message}
                                    </span>
                                  )}
                                  {errors.password_repeat.type ===
                                    "minLength" && (
                                    <span className="small error">
                                      {errors.password_repeat.message}
                                    </span>
                                  )}
                                  {errors.password_repeat.type ===
                                    "validate" && (
                                    <span className="small error">
                                      Passwords do not match
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                tabIndex={8}
                                id="form3Example4"
                                placeholder="Address Line 2"
                                {...register("userCity", {
                                  maxLength: {
                                    value: 50,
                                    message: "Too many characters",
                                  },
                                })}
                              />
                              {errors.userCity ? (
                                <>
                                  {errors.userCity.type === "required" && (
                                    <span className="small error">
                                      {errors.userCity.message}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                tabIndex={9}
                                id="form3Example4"
                                placeholder="Post Code / Zip"
                                {...register("userPostcode", {
                                  maxLength: {
                                    value: 50,
                                    message: "Too many characters",
                                  },
                                })}
                              />
                              {errors.userPostcode ? (
                                <>
                                  {errors.userPostcode.type === "maxLength" && (
                                    <span className="small error">
                                      {errors.userPostcode.message}
                                    </span>
                                  )}
                                </>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <div className="custom_select">
                                <select
                                  className="form-control select-active"
                                  {...register("Country", {
                                    required: "Select your country",
                                  })}
                                  tabIndex={10}
                                >
                                  <option value="">Select country...</option>
                                  {countries &&
                                    countries.map((item: any) => (
                                      <option value={item.countryID}>
                                        {item.name}{" "}
                                      </option>
                                    ))}
                                </select>
                                {errors.Country ? (
                                  <>
                                    <span className="small error">
                                      {errors.Country.message}
                                    </span>
                                  </>
                                ) : null}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-6">
                                  <div style={{ display: "flex" }}>
                                    <input
                                      style={{
                                        width: "20px",
                                        paddingTop: "20px",
                                      }}
                                      type="checkbox"
                                      id="exampleCheckbox12"
                                      {...register("userSubscribe", {})}
                                      value=""
                                    ></input>
                                    <label
                                      style={{
                                        paddingLeft: "10px",
                                        paddingTop: "10px",
                                      }}
                                      className="form-check-label"
                                    >
                                      <span>Mailing List</span>
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div style={{ display: "flex" }}>
                                    <input
                                      style={{
                                        width: "20px",
                                        paddingTop: "20px",
                                      }}
                                      type="checkbox"
                                      id="exampleCheckbox12"
                                      {...register("account", {})}
                                      value=""
                                    ></input>
                                    <label
                                      style={{
                                        paddingLeft: "10px",
                                        paddingTop: "10px",
                                      }}
                                      className="form-check-label"
                                    >
                                      <span>Account Customer</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr></hr>
                        </div>

                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-fill-out btn-block hover-up"
                            name="login"
                          >
                            {loading ? <ButtonLoaderSmall /> : null}

                            {loading ? "" : <span>Submit &amp; Register</span>}
                          </button>
                          <hr></hr>
                          {errorMessage ? (
                            <h5 className="error">{errorMessage}</h5>
                          ) : null}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
export default CreateAccount;
