import React, { ReactElement, useState, useEffect } from "react";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { useNavigate } from "react-router";
import { ApiService } from "../../../services/api.service";
import { useLocation } from "react-router";

function MediaImages(): ReactElement {
  const location = useLocation();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const apiService = new ApiService();

  const [mediaImages, setMediaImages] = useState<any | null>(null);

  const fetchExchange = async () => {
    const response = await apiService.getAllMediaImages();
    setMediaImages(response);
  };

  useEffect(() => {
    fetchExchange();
  }, []);
  return (
    <div>
      <AdminHeader></AdminHeader>

      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Media Images</h1>
              <div className="clearfix"></div>
            </div>
            <div className="row">
              <div className="col-md-4 text-center border-bottom pb-2 mb-3">
                <h4>UK Images</h4>
                <hr></hr>

                {mediaImages &&
                  mediaImages.map((item: any) =>
                    item.mediaCountry === 1 ? (
                      <img
                        src={
                          window.location.origin +
                          `/Admin/slides/` +
                          item.mediaImagePath
                        }
                        className="img-thumbnail"
                        style={{ width: "100%", marginTop: "-1px" }}
                        alt="No image"
                      ></img>
                    ) : null
                  )}
              </div>
              <div className="col-md-4 text-center border-bottom pb-2 mb-3">
                <h4>US Images</h4>

                <hr></hr>
                {mediaImages &&
                  mediaImages.map((item: any) =>
                    item.mediaCountry === 2 ? (
                      <img
                        src={
                          window.location.origin +
                          `/Admin/slides/` +
                          item.mediaImagePath
                        }
                        className="img-thumbnail"
                        style={{ width: "100%", marginTop: "-1px" }}
                        alt="No image"
                      ></img>
                    ) : null
                  )}
              </div>
              <div className="col-md-4 text-center border-bottom pb-2 mb-3">
                <h4>Default/Generic Images</h4>

                <hr></hr>
                {mediaImages &&
                  mediaImages.map((item: any) =>
                    item.mediaCountry === 0 ? (
                      <img
                        src={
                          window.location.origin +
                          `/Admin/slides/` +
                          item.mediaImagePath
                        }
                        className="img-thumbnail"
                        style={{ width: "100%", marginTop: "-1px" }}
                        alt="No image"
                      ></img>
                    ) : null
                  )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
export default MediaImages;
