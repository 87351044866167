import React, { ReactElement } from "react";
function ContactUs(): ReactElement {
  return (
    <div>
      <div className="container" style={{ marginTop: "20px" }}>
        <h2>Contact Us</h2>
        <hr></hr>
        <div className="row">
          <div className="col-md-6">
            <div className="videoWrapper mb-25">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2386.050898275287!2d-2.1185168876301472!3d53.27070767215172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487a4941bf7a2653%3A0xb5593be0b993e650!2sMark%20Andy%20UK%20Ltd!5e0!3m2!1sen!2suk!4v1673733449999!5m2!1sen!2suk"
                width="600"
                height="450"
                style={{ border: "0" }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <h4>Mark Andy Europe</h4>
            <br></br>
            Queens Avenue<br></br>
            Hurdsfield Industrial Estate<br></br>
            Macclesfield, Cheshire SK10 2BN<br></br>
            England<br></br>
            Phone +44 1 625 500 964<br></br>
            Fax +44 1 625 501 334<br></br> <br></br>
          </div>

          <div className="col-md-6">
            <div className="videoWrapper mb-25">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3115.0339893265636!2d-90.6420793541124!3d38.67108690960028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ded520580057ef%3A0x701f2fad1bee4738!2sMark%20Andy%20Inc!5e0!3m2!1sen!2suk!4v1673733570862!5m2!1sen!2suk"
                width="600"
                height="450"
                style={{ border: "0" }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <h4>Mark Andy Inc.</h4>
            <br></br>
            18081 Chesterfield Airport Road<br></br>
            Chesterfield<br></br>
            USA<br></br>
            MO 63005<br></br>
            Tel - 001 636 532 4433<br></br>
            USA Toll Free 800 700 6275
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactUs;

<br></br>;
