import React, { ReactElement, useState, useEffect } from "react";
import { ApiService } from "../../services/api.service";
import { Link } from "react-router-dom";
import { Truncate } from "../../helpers";
import Loader from "../Loader";

function Highlights(): ReactElement {
  const [products1, setProducts1] = useState([]);
  const [products2, setProducts2] = useState([]);
  const [products3, setProducts3] = useState([]);

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  const apiService = new ApiService();

  const id1 = 28;
  const id2 = 27;
  const id3 = 21;

  const fetchProductsByCategoryID1 = async (e: number) => {
    setLoading1(true);
    const response = await apiService.getProductCategoryByCategoryID(e);
    setProducts1(response);
    setLoading1(false);
  };

  const fetchProductsByCategoryID2 = async (e: number) => {
    setLoading2(true);
    const response = await apiService.getProductCategoryByCategoryID(e);
    setProducts2(response);
    setLoading2(false);
  };
  const fetchProductsByCategoryID3 = async (e: number) => {
    setLoading3(true);
    const response = await apiService.getProductCategoryByCategoryID(e);
    setProducts3(response);
    setLoading3(false);
  };

  useEffect(() => {
    fetchProductsByCategoryID1(Number(id1));
    fetchProductsByCategoryID2(Number(id2));
    fetchProductsByCategoryID3(Number(id3));
  }, []);

  return (
    <div>
      <section>
        <h3 className="section-title mb-20">
          <span>Flexo</span> Consumables
        </h3>
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-sm-5 mb-md-0">
            <div className="banner-img wow fadeIn animated mb-md-4 mb-lg-0">
              <img
                src={window.location.origin + `/imgs/banner/banner-10a.png`}
                alt="text"
              ></img>
              <div className="banner-text">
                <span>Flexo Consumables</span>
                <h4>
                  Save on <br></br>All Items
                </h4>
                <Link to="/search?p=CCFBA11A-D877-4B96-B8A3-9E6E95E2B8A6">
                  Shop Now <i className="fi-rs-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-sm-5 mb-md-0">
            <h4 className="section-title style-1 mb-30 wow fadeIn animated">
              Tesa Tape{" "}
            </h4>
            {loading1 ? (
              <Loader />
            ) : (
              <div className="product-list-small wow fadeIn animated">
                {products1 &&
                  products1.slice(0, 4).map((item: any) => (
                    <article className="row align-items-center">
                      <figure className="col-md-4 mb-0">
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/product?id=${item.productID}&name=${item.productName}`}
                        >
                          <img
                            className="card-img-top img-thumbnail"
                            src={
                              window.location.origin +
                              `/admin/catalogue/${item.productName}.jpg`
                            }
                            style={{
                              maxHeight: "70px",
                              minHeight: "70px",
                              objectFit: "cover",
                              width: "100%",
                            }}
                            alt=""
                          ></img>
                        </Link>
                      </figure>
                      <div className="col-md-8 mb-0">
                        <h4>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/product?id=${item.productID}&name=${item.productName}`}
                          >
                            {" "}
                            {item.productName}
                          </Link>
                        </h4>
                        <p className="title-small">
                          {Truncate(item.productDetails!)}{" "}
                        </p>
                      </div>
                    </article>
                  ))}
              </div>
            )}
          </div>
          <div className="col-lg-3 col-md-6 mb-sm-5 mb-md-0">
            <h4 className="section-title style-1 mb-30 wow fadeIn animated">
              Consumables{" "}
            </h4>
            {loading2 ? (
              <Loader />
            ) : (
              <div className="product-list-small wow fadeIn animated">
                {products2 &&
                  products2.slice(0, 4).map((item: any) => (
                    <article className="row align-items-center">
                      <figure className="col-md-4 mb-0">
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/product?id=${item.productID}&name=${item.productName}`}
                        >
                          <img
                            className="card-img-top img-thumbnail"
                            src={
                              window.location.origin +
                              `/admin/catalogue/${item.productName}.jpg`
                            }
                            style={{
                              maxHeight: "70px",
                              minHeight: "70px",
                              objectFit: "cover",
                              width: "100%",
                            }}
                            alt=""
                          ></img>
                        </Link>
                      </figure>
                      <div className="col-md-8 mb-0">
                        <h4>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/product?id=${item.productID}&name=${item.productName}`}
                          >
                            {item.productName}
                          </Link>
                        </h4>
                        <p className="title-small">
                          {Truncate(item.productDetails!)}{" "}
                        </p>
                      </div>
                    </article>
                  ))}
              </div>
            )}
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title style-1 mb-30 wow fadeIn animated">
              Performance Series{" "}
            </h4>
            {loading2 ? (
              <Loader />
            ) : (
              <div className="product-list-small wow fadeIn animated">
                {products3 &&
                  products3.slice(0, 4).map((item: any) => (
                    <article className="row align-items-center">
                      <figure className="col-md-4 mb-0">
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/product?id=${item.productID}&name=${item.productName}`}
                        >
                          <img
                            className="card-img-top img-thumbnail"
                            src={
                              window.location.origin +
                              `/admin/catalogue/${item.productName}.jpg`
                            }
                            style={{
                              maxHeight: "70px",
                              minHeight: "70px",
                              objectFit: "cover",
                              width: "100%",
                            }}
                            alt=""
                          ></img>
                        </Link>
                      </figure>
                      <div className="col-md-8 mb-0">
                        <h4>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/product?id=${item.productID}&name=${item.productName}`}
                          >
                            {item.productName}
                          </Link>
                        </h4>
                        <p className="title-small">
                          {Truncate(item.productDetails!)}
                        </p>
                      </div>
                    </article>
                  ))}
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
export default Highlights;
