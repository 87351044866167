import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

function Manuals(): ReactElement {
  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span></span>
            Manuals
          </div>
        </div>
      </div>
      <section className="pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 m-auto">
              <div className="row">
                <h2>Manuals</h2>

                <hr style={{ marginTop: "20px" }}></hr>
                <ul className="list-group" style={{ marginTop: "20px" }}>
                  <a href="/manuals/P3-10.pdf" target="_blank">
                    <li className="list-group-item">P3-10</li>
                  </a>
                  <a href="/manuals/P3-13.pdf" target="_blank">
                    <li className="list-group-item">P3-13</li>
                  </a>
                  <a href="/manuals/P3-17.pdf" target="_blank">
                    <li className="list-group-item">P3-17</li>
                  </a>
                  <a href="/manuals/P5-10.pdf" target="_blank">
                    <li className="list-group-item">P5-10</li>
                  </a>
                  <a href="/manuals/P5-13.pdf" target="_blank">
                    <li className="list-group-item">P5-13</li>
                  </a>
                  <a href="/manuals/P5-17.pdf" target="_blank">
                    <li className="list-group-item">P5-17</li>
                  </a>

                  <a href="/manuals/P7-13.pdf" target="_blank">
                    <li className="list-group-item">P7-13</li>
                  </a>
                  <a href="/manuals/P7-17.pdf" target="_blank">
                    <li className="list-group-item">P7-17</li>
                  </a>
                  <a href="/manuals/P7-20.pdf" target="_blank">
                    <li className="list-group-item">P7-20</li>
                  </a>

                  <a href="/manuals/830.pdf" target="_blank">
                    <li className="list-group-item">830</li>
                  </a>
                  <a href="/manuals/2200.pdf" target="_blank">
                    <li className="list-group-item">2200</li>
                  </a>
                  <a href="/manuals/4150.pdf" target="_blank">
                    <li className="list-group-item">4150</li>
                  </a>
                  <a href="/manuals/DigitalPro.pdf" target="_blank">
                    <li className="list-group-item">
                      Digital Pro Engine Manual
                    </li>
                  </a>
                  <a href="/manuals/DigitalOne.pdf" target="_blank">
                    <li className="list-group-item">
                      Digital One Engine Manual
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Manuals;
