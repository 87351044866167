import { url } from "inspector";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Truncate } from "../../helpers";

function Featured(): ReactElement {
  const featured1 = "0BCA237E-E5DF-471B-8356-24731CB7C65B";
  const featured2 = "8940cf82-6776-49b4-bc0e-8341854e4149";
  const featured3 = "D1BBE154-FA66-4C57-BCD6-5FCB9E064BB5";
  return (
    <section className="banners mb-20 mt-20">
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="banner-img wow fadeIn animated">
            <img
              src={window.location.origin + `/imgs/banner/ad_blades.jpg`}
              alt="text"
            ></img>

            <div className="banner-text">
              <span>Flexo Consumables</span>
              <h4>
                Save on <br></br>Dr Blades
              </h4>
              <Link to={`/search?s=${featured1.toUpperCase()}`}>
                Shop Now <i className="fi-rs-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="banner-img wow fadeIn animated">
            <img
              src={window.location.origin + `/imgs/banner/ad_tesa.jpg`}
              alt="text"
            ></img>

            <div className="banner-text">
              <span>Tesa Tape</span>
              <h4>
                Deals on <br></br>all rolls
              </h4>

              <Link to={`/search?p=${featured2.toUpperCase()}`}>
                Shop Now <i className="fi-rs-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 d-md-none d-lg-flex">
          <div className="banner-img wow fadeIn animated  mb-sm-0">
            <img
              src={window.location.origin + `/imgs/banner/ad_consumables.jpg`}
              alt="text"
            ></img>

            <div className="banner-text">
              <span>Anilox Rolls</span>
              <h4>
                Order for <br></br>fast delivery
              </h4>
              <Link to={`/search?s=${featured3.toUpperCase()}`}>
                Shop Now <i className="fi-rs-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Featured;
