import React, { ReactElement, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { currencySelector, FormatDate } from "../../../helpers";
import { ApiService } from "../../../services/api.service";
import Loader from "../../Loader";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { Badge, Form, FormLabel } from "react-bootstrap";
import { useLocation } from "react-router";

function Orders(): ReactElement {
  const location = useLocation();

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [orders, setOrders] = useState([]);

  const apiService = new ApiService();

  const navigate = useNavigate();

  const fetchAllOrders = async () => {
    setLoading(true);
    const response = await apiService.getOrders();
    setOrders(response);

    setLoading(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchAllOrders();
  }, []);

  function handleClick(item: any) {
    navigate("/Administration/OrderView?id=" + item);
  }

  const handleKeyword = (e: any) => {
    if (e.length >= 3) {
      setLoading(true);
      setSearchKeyword(e);
      const apiService = new ApiService();
      const fetchOrdersByKeyword = async () => {
        const response = await apiService.getOrdersByKeyword(e);
        setOrders(response);
        setLoading(false);
      };

      fetchOrdersByKeyword();
    }
  };

  return (
    <div>
      <AdminHeader></AdminHeader>

      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Complete Orders</h1>
              {/* <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                  >
                    Share
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                  >
                    Export
                  </button>
                </div>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary dropdown-toggle"
                >
                  <span
                    data-feather="calendar"
                    className="align-text-bottom"
                  ></span>
                  This week
                </button>
              </div> */}
            </div>

            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Customer Search</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter keyword"
                  style={{ textAlign: "left", marginBottom: "0px" }}
                  onChange={(e) => handleKeyword(e.target.value)}
                />
                <Form.Text className="text-muted">
                  Type to search for a customer last name, order number or
                  business name
                </Form.Text>
              </Form.Group>
            </Form>

            {loading ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                <table className="table table-sm admin">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">User</th>
                      <th style={{ width: "40%" }} scope="col">
                        Address
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Date
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Sub Total (excl Delivery)
                      </th>
                      <th scope="col">Discount</th>
                      <th scope="col">PO No.</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders &&
                      orders.map((item: any) => (
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClick(item.saleID)}
                        >
                          <td>{"MA00" + item.saleID}</td>
                          <td>{item.userFirstname + " " + item.userSurname}</td>
                          <td>{item.deliveryAddress}</td>
                          <td>{FormatDate(item.saleDate)}</td>
                          <td>
                            {currencySelector(
                              item.currencyID,
                              item.saleGBPTotal
                            )}
                          </td>
                          <td>
                            {" "}
                            {currencySelector(
                              item.currencyID,
                              item.discountApplied
                            )}
                          </td>
                          <td>{item.poNumber}</td>
                          <td>
                            {item.saleComplete === 1 ? (
                              <Badge bg="success" style={{ fontSize: 12 }}>
                                Complete
                              </Badge>
                            ) : (
                              <Badge bg="danger" style={{ fontSize: 12 }}>
                                Pending
                              </Badge>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
}
export default Orders;
