import React, { ReactElement, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { generateUUID } from "../../helpers";

import { CartContext } from "../../context/cartContext";
import {
  FormatBasePrice,
  FormatGeneralPrice,
  FormatMoney,
} from "../../helpers";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context/authContext";
import { ApiService } from "../../services/api.service";
import { FormatDate, CalculateDiscount } from "../../helpers";
import { Link } from "react-router-dom";
import ButtonLoaderSmall from "../ButtonLoaderSmall";
import Cookie from "js-cookie";
import { CountryContext } from "../../context/countriesContext";

const cookieRetrieve = Cookie.get("token");
function Checkout(): ReactElement {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    resetField,

    formState: { errors },
  } = useForm({
    defaultValues: {
      deliveryBuildingNo: "",
      countryID: 0,
      deliveryID: 0,
      deliveryPostalcode: "",
      deliveryRegion: "",
      deliveryStreet: "",
      userID: 22,
      contactName: "",
    },
  });

  const { userData } = useContext(AuthContext);
  const [userID, setUserID] = useState(0);
  let id = userData.userGuid;

  const inputRef = React.useRef(null);
  const formRef = React.useRef(null) as any;

  const { cart, setCart } = useContext(CartContext);

  const { countryData, setCountryData } = useContext(CountryContext);
  const { totalPrice, setTotalPrice } = useContext(CartContext);
  const total = totalPrice;

  const [saleCompleteID, setSaleCompleteID] = useState(0);

  const [processingSale, setProcessingSale] = useState(false);

  const [showDelForm, setShowDelForm] = useState(false);

  const [hasOneDeliveryAddress, setHasOneDeliveryAddress] = useState(false);

  const [addingDelAddress, setAddingDelAddress] = useState(true);

  const apiService = new ApiService();

  const [countries, setCountries] = useState([] as any);
  const cartData = cart;

  const navigate = useNavigate();

  const [promoCode, setPromoCode] = useState("");
  const [promoCodeResponse, setPromoCodeResponse] = useState([] as any);
  const [promoSuccess, setPromoSuccess] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [PoNumber, setPoNumber] = useState("");

  const [promoDiscount, setPromoDiscount] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);

  const [promoApplied, setPromoApplied] = useState("");

  const [deliveryCountry, setdeliveryCountry] = useState("");

  const [deliveryAddresses, setDeliveryAddresses] = useState([] as any);

  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(
    [] as any
  );

  const [chosenDeliveryAddress, setChosenDeliveryAddress] = useState([] as any);

  const emptyCustomerData = {
    deliveryBuildingNo: "",
    countryID: 0,
    deliveryID: 0,
    deliveryPostalcode: "",
    deliveryRegion: "",
    deliveryStreet: "",
    userID: 22,
    contactName: "",
  };

  const [saleItems, setSaleItems] = useState([
    {
      saleItemID: 0,
      ProductName: "",
      ProductID: 0,
      ProductQuantity: 0,
      saleID: 0,
      salePrice: 0,
      serial: "",
      machine: "",
      DeliveryCharge: 0,
      productVariantID: 0,
      subProductVariantID: 0,
    },
  ]);

  const fetchDeliveryAddresses = async (id: string) => {
    const response = await apiService.getDeliveryAddressesByUserID(id);

    if (response.length > 0) {
      setHasOneDeliveryAddress(true);
    }
    setDeliveryAddresses(response);
    if (response === null) {
      setAddingDelAddress(true);
    } else {
      var lastItem = response.slice(-1)[0];
      fetchDeliveryAddress(lastItem.deliveryID);
    }
  };

  const [productsBought, setProductsBought] = useState("");

  const fetchAllCountries = async () => {
    const response = await apiService.getCountries();
    setCountries(response);
    //fetchDeliveryAddresses(id);
  };

  const findAddress = (deliveryAddresses: any, id: any) => {
    return deliveryAddresses.find((element: any) => {
      return element.deliveryID === id;
    });
  };

  const handleShipping = (e: any) => {
    setErrorMessage("");
    if (e !== "0") {
      fetchDeliveryAddress(e);

      setSelectedDeliveryAddress([]);
    } else {
      setSelectedDeliveryAddress([]);
      fetchDeliveryAddress(0);
      setErrorMessage("A delivery address is required to be selected");
    }
  };

  const fetchCountryByCountryID = async (id: number) => {
    const response = await apiService.getCountryByCountryID(id);
    setdeliveryCountry(response.cont);
  };

  // const setTheDeliveryCharge = async () => {
  //   if (
  //     selectedDeliveryAddress.deliveryCountry === 247 &&
  //     userData.currency === 2
  //   ) {
  //     setDeliveryCharge(15.0);
  //   } else if (deliveryCountry === "Europe") {
  //     setDeliveryCharge(42);
  //   } else {
  //     setDeliveryCharge(0);
  //   }
  // };

  const findCountryReference = (countryData: any, id: any) => {
    return countryData.find((element: any) => {
      return element.countryID === id;
    });
  };

  const fetchDeliveryAddress = async (id: number) => {
    //get the country reference
    if (id !== 0) {
      const response = await apiService.getDeliveryAddressByID(id);
      setSelectedDeliveryAddress(response);
      fetchCountryByCountryID(response.countryID);

      var item = findCountryReference(countryData, response.countryID);

      if (response.countryID === 247) {
        setDeliveryCharge(15.0);
      } else if (item.cont === "Europe") {
        setDeliveryCharge(42);
      } else {
        setDeliveryCharge(0.0);
      }

      reset(response);
    } else {
      setSelectedDeliveryAddress([]);
    }
  };

  // useEffect(() => {}, [selectedDeliveryAddress]);

  const onSubmit = (data: any) => {
    var newObj = Object.assign({}, data);

    if (selectedDeliveryAddress.deliveryID !== undefined) {
      newObj.deliveryID = selectedDeliveryAddress.deliveryID;
    } else {
      newObj.deliveryID = 0;
      newObj.contactName = data.contactName;
      newObj.userID = parseInt(userData.userID);
      newObj.countryID = parseInt(newObj.countryID);
    }

    PostData(newObj);
  };

  const checkPromoCode = (e: any) => {
    {
      if (
        FormatDate(e.dateStart) <= FormatDate(new Date()) &&
        FormatDate(e.dateEnd) >= FormatDate(new Date()) &&
        e.promoStatus == 1 &&
        e.promoCont == "Europe" &&
        e.promoCode === promoCode
      ) {
        setPromoSuccess("code valid");
        setPromoDiscount(
          CalculateDiscount(promoCodeResponse.promoValue, total)
        );
        setPromoApplied(promoCode);
      } else {
        setPromoSuccess("code invalid");
        setPromoApplied("");
        setPromoDiscount(0);
      }
    }
  };

  const addDeliveryAddress = () => {
    setAddingDelAddress(!addingDelAddress);
    setShowDelForm(!showDelForm);
    resetField("deliveryStreet");
    resetField("deliveryBuildingNo");
    resetField("deliveryStreet");
    resetField("deliveryRegion");
    resetField("countryID");
    resetField("deliveryPostalcode");
    setSelectedDeliveryAddress([]);
    if (formRef.current != undefined) {
      formRef.current.reset();
    }
    setSelectedDeliveryAddress([]);
  };

  const clearForm = () => {
    resetField("deliveryStreet");
    resetField("deliveryBuildingNo");
    resetField("deliveryStreet");
    resetField("deliveryRegion");
    resetField("countryID");
    resetField("deliveryPostalcode");
    setSelectedDeliveryAddress([]);
    if (formRef.current != undefined) {
      formRef.current.reset();
    }
  };

  const EditDeliveryAddress = () => {
    //setAddingDelAddress((addingDelAddress) => !addingDelAddress);
  };

  const handlePromoCode = (e: any) => {
    fetchPromoCode(e.target.value);
    setPromoCode(e.target.value);
  };

  const handlePONumber = (e: any) => {
    setPoNumber(e.target.value);
  };

  const handleCoupon = (e: any) => {
    fetchPromoCode(promoCode);
    checkPromoCode(promoCodeResponse);
  };

  const fetchPromoCode = async (promo: string) => {
    const response = await apiService.getPromoCode(promo);
    if (response.promoCode) {
      setPromoCodeResponse(response);
    }
  };

  const FinaliseTotal = (total: any, deliveryCharge: any, discount: any) => {
    var newTotal = total;
    var discCalc = discount;

    var calcTotal = newTotal + deliveryCharge + discCalc;

    return FormatGeneralPrice(calcTotal);
  };

  const PostData = async (data: any) => {
    const response = await apiService.PostDeliveryAddress(
      "api/DeliveryAddress/SaveDeliveryAddress",
      data
    );

    if (response === 1) {
      fetchDeliveryAddresses(String(userData.userGuid));
      addDeliveryAddress();
      setHasOneDeliveryAddress(true);
      setErrorMessage("");
    }
  };

  const makeSaleItems = async (saleCompleteID: number) => {
    cart &&
      cart.map((item, index) =>
        saleItems.push({
          saleItemID: 0,
          ProductName: item.product.productName!,
          ProductID: item.productID!,
          ProductQuantity: item.productQuantity!,
          saleID: saleCompleteID!,
          salePrice: item.price!,
          serial: "",
          machine: "",
          DeliveryCharge: deliveryCharge,
          productVariantID: 0,
          subProductVariantID: item.subProductVariantID!,
        })
      );
  };

  const placeOrder = async () => {
    if (
      selectedDeliveryAddress == undefined ||
      selectedDeliveryAddress === null ||
      selectedDeliveryAddress.length === 0
    ) {
      setErrorMessage(
        "A delivery address is required to be selected. Please select one or add a new one from the options"
      );
      return;
    } else {
      setErrorMessage("");
    }

    if (PoNumber === "") {
      setErrorMessage(
        "A purchase order number is required to complete your order"
      );
      return;
    } else {
      setErrorMessage("");
    }

    setProcessingSale(true);

    window.scrollTo(0, 0);

    let totalClosed = "";
    let cartGuid = "";

    cart.forEach(function (arrayItem) {
      totalClosed = totalClosed + arrayItem.product.productName + "<br>";
      cartGuid = arrayItem.guid !== undefined ? arrayItem.guid.toString() : "";
    });

    const address = findAddress(
      deliveryAddresses,
      selectedDeliveryAddress.deliveryID
    );

    let vaccineData = [] as any;

    vaccineData = countryData.find(
      (state: any) => state.countryID === selectedDeliveryAddress.countryID
    );

    setProductsBought(totalClosed);
    const saleData = {
      productID: 0,
      userGuid: userData.userGuid,
      saleGBPTotal: totalPrice,
      saleEuroTotal: "",
      saleDate: new Date(),
      saleComplete: 1,
      saleDespatch: "01/01/2001",
      cartGuid: cartGuid,
      deliveryID: selectedDeliveryAddress.deliveryID,
      currencyID: userData.currency,
      PONumber: PoNumber,
      DiscountApplied: promoDiscount,
      DeliveryAddress:
        address.deliveryBuildingNo +
        " " +
        address.deliveryStreet +
        " " +
        address.deliveryRegion +
        " " +
        address.deliveryPostalcode +
        " " +
        vaccineData.name,
      ProductsBought: totalClosed,
      discountcouponused: promoApplied,
    };

    setTimeout(() => {
      setProcessingSale(true);
    }, 9000);

    const response = await apiService.PostDataWithAuth(
      "api/Sale/AddSale",
      saleData
    );
    if (response) {
      setSaleCompleteID(response);
      makeSaleItems(response);

      const response2 = await apiService.PostDataWithAuth(
        "api/SaleItem/AddSaleItems",
        saleItems
      );
      if (response2) {
        Cookie.set("cart", generateUUID());
        const cookieVal = Cookie.get("cart");
        const response3 = await apiService.getCart(cookieVal!);
        setCart(response3);
        navigate("/Sale-Complete/?id=" + response);

        setProcessingSale(false);
      }
    }
    setProcessingSale(false);
  };

  useEffect(() => {
    //fetchAllCountries();
    fetchDeliveryAddresses(userData.userGuid);
  }, [userData.userID]);

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span></span> Shop
            <span></span> Checkout
          </div>
        </div>
      </div>

      {processingSale ? (
        <div
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            paddingTop: "80px",
            width: "100vw",
          }}
        >
          <h1>Completing your order</h1>
          <img src="./imgs/loader2.gif" style={{ width: "200px" }} alt=""></img>
        </div>
      ) : null}

      <section className={`${processingSale ? "disabled" : null} mt-50 mb-50`}>
        <div className="container">
          {cart.length > 0 ? (
            <div>
              <h2>Checkout</h2>
              <hr></hr>

              <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-md-12">
                  <div className="row mb-50">
                    <div className="col-md-12">
                      <div className="order_review">
                        <div className="mb-20">
                          <h4>Your Order</h4>
                        </div>
                        <div className="table-responsive order_table text-center">
                          <table className="table">
                            <thead>
                              <tr
                                className="main-heading"
                                style={{ background: "#eee", color: "#333" }}
                              >
                                <th scope="col" style={{ width: "7%" }}>
                                  Image
                                </th>
                                <th style={{ textAlign: "left" }} scope="col">
                                  Name
                                </th>
                                <th scope="col">Price</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cart &&
                                cart.map((item, index) => (
                                  <tr key={item.productID}>
                                    <td className="text-left image product-thumbnail">
                                      <img
                                        src={
                                          window.location.origin +
                                          `/Admin/Catalogue/${item.product?.productName}.jpg`
                                        }
                                        style={{
                                          width: "100%",
                                        }}
                                        alt="text"
                                      ></img>
                                    </td>
                                    <td
                                      className="product-des product-name"
                                      style={{ textAlign: "left" }}
                                    >
                                      <h3 className="product-name">
                                        <Link
                                          to={`/product?id=${item.product?.productID}&name=${item.product?.productName}`}
                                        >
                                          {item.product?.productName}
                                        </Link>
                                      </h3>
                                      <p className="font-sm">
                                        {item.subProductVariant
                                          ? item.subProductVariant
                                              .productVariantDetails + "\n"
                                          : null}
                                        {""}
                                        {item.product?.productDetails}
                                      </p>
                                    </td>
                                    <td className="price" data-title="Price">
                                      <span>
                                        {FormatGeneralPrice(item.price!)}{" "}
                                      </span>
                                    </td>
                                    <td
                                      className="text-center"
                                      data-title="Stock"
                                    >
                                      <div className="detail-qty  m-auto">
                                        {" "}
                                        <span>{item.productQuantity}</span>
                                      </div>
                                    </td>
                                    <td
                                      className="text-right"
                                      data-title="Cart"
                                    >
                                      <span>
                                        {FormatGeneralPrice(
                                          item.price! * item.productQuantity!
                                        )}{" "}
                                      </span>
                                    </td>
                                  </tr>
                                ))}

                              <tr>
                                <td colSpan={6} className="text-end"></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>{" "}
                        <div className="row mt-30">
                          <div className="heading_s1 mb-3">
                            <h4>Delivery Address</h4>
                          </div>
                          <div
                            className="col-md-6"
                            style={{ background: "#efefef", padding: "25px" }}
                          >
                            <div className="form-group col-lg-12">
                              <div className="custom_select">
                                {hasOneDeliveryAddress ? (
                                  <select
                                    onChange={(e) =>
                                      handleShipping(e.target.value)
                                    }
                                    className="form-select select-active"
                                    style={{
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <option value="0">
                                      Choose or add a delivery address...
                                    </option>
                                    {deliveryAddresses &&
                                      deliveryAddresses.map(
                                        (item: any, index: any) =>
                                          item.userID !== 0 ? (
                                            <option
                                              selected={
                                                index ===
                                                deliveryAddresses.length - 1
                                              }
                                              value={item.deliveryID}
                                            >
                                              {item.deliveryBuildingNo +
                                                " " +
                                                item.deliveryStreet +
                                                " " +
                                                item.deliveryRegion +
                                                " " +
                                                item.deliveryPostalcode}
                                            </option>
                                          ) : null
                                      )}
                                  </select>
                                ) : null}
                              </div>
                              <span
                                className="prevent-select"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "15px",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                                onClick={(e) => addDeliveryAddress()}
                              >
                                Add/edit delivery address
                              </span>

                              <span
                                className="prevent-select"
                                style={{
                                  cursor: "pointer",
                                  marginTop: "15px",
                                  marginLeft: "5px",
                                  marginRight: "5px",
                                }}
                                onClick={(e) => clearForm()}
                              >
                                {showDelForm ? "Clear form" : null}
                              </span>
                              {/* {selectedDeliveryAddress.deliveryID != 0 ? (
                                  <span
                                    className="prevent-select"
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "15px",
                                      marginLeft: "5px",
                                    }}
                                    onClick={(e) => EditDeliveryAddress()}
                                  >
                                    Edit this delivery address
                                  </span>
                                ) : null} */}
                            </div>
                            <form
                              onSubmit={handleSubmit(onSubmit)}
                              className="field_form shipping_calculator"
                              ref={formRef}
                            >
                              {!addingDelAddress ? (
                                <div>
                                  <div className="form-row">
                                    <div className="form-group col-lg-6"></div>
                                  </div>
                                  <div className="form-row row">
                                    <div className="form-group col-lg-12">
                                      <input
                                        placeholder="Contact Name"
                                        {...register("contactName", {})}
                                        type="text"
                                        defaultValue={
                                          selectedDeliveryAddress.contactName
                                        }
                                      ></input>
                                    </div>

                                    <div className="form-group col-lg-6">
                                      <input
                                        placeholder="Address Line 1"
                                        type="text"
                                        defaultValue={
                                          selectedDeliveryAddress.deliveryBuildingNo
                                        }
                                        {...register("deliveryBuildingNo", {})}
                                      ></input>
                                      <input
                                        placeholder="Address line 2"
                                        {...register("deliveryStreet", {})}
                                        type="text"
                                        defaultValue={
                                          selectedDeliveryAddress.deliveryStreet
                                        }
                                      ></input>

                                      <div className="form-group">
                                        <div className="custom_select">
                                          <select
                                            className="form-control select-active"
                                            {...register("countryID", {})}
                                          >
                                            <option value="">
                                              Select country...
                                            </option>
                                            {countryData &&
                                              countryData.map((item: any) => (
                                                <option
                                                  value={item.countryID}
                                                  selected={
                                                    selectedDeliveryAddress.countryID ===
                                                    item.countryID
                                                  }
                                                >
                                                  {item.name}{" "}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group col-lg-6">
                                      <input
                                        placeholder="Region"
                                        {...register("deliveryRegion", {})}
                                        type="text"
                                        defaultValue={
                                          selectedDeliveryAddress.deliveryRegion
                                        }
                                      ></input>
                                      <input
                                        placeholder="PostCode / ZIP"
                                        {...register("deliveryPostalcode", {})}
                                        type="text"
                                        defaultValue={
                                          selectedDeliveryAddress.deliveryPostalcode
                                        }
                                      ></input>
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="form-group col-lg-12">
                                      <button className="btn  btn-sm">
                                        <i className="fi-rs-shuffle mr-10"></i>
                                        Add / update delivery address
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </form>

                            <div className="mb-30 mt-50">
                              <div className="heading_s1 mb-3">
                                <h4>PO Number</h4>
                              </div>
                              <div className="total-amount">
                                <div className="left">
                                  <div className="coupon">
                                    <div className="form-row row justify-content-center">
                                      <div className="form-group col-lg-6">
                                        <input
                                          className="font-medium"
                                          name="Coupon"
                                          placeholder="Enter Your PO Number"
                                          onChange={(e) => handlePONumber(e)}
                                          required
                                        ></input>
                                      </div>
                                      <div className="form-group col-lg-6">
                                        <div className="clearfix"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mb-30 mt-50">
                              <div className="heading_s1 mb-3">
                                <h4>Apply Coupon</h4>
                              </div>
                              <div className="total-amount">
                                <div className="left">
                                  <div className="coupon">
                                    <div className="form-row row justify-content-center">
                                      <div className="form-group col-lg-6">
                                        <input
                                          className="font-medium"
                                          name="Coupon"
                                          placeholder="Enter Your Coupon"
                                          onChange={(e) => handlePromoCode(e)}
                                        ></input>
                                      </div>
                                      <div className="form-group col-lg-6">
                                        <button
                                          onClick={(e) => handleCoupon(e)}
                                          className="btn  btn-sm"
                                        >
                                          <i className="fi-rs-label mr-10"></i>
                                          Apply
                                        </button>
                                        <div className="clearfix"></div>
                                      </div>
                                      <span className="small error">
                                        {promoSuccess}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="border p-md-4 p-30 border-radius cart-totals">
                              <div className="heading_s1 mb-3">
                                <h4>Cart Totals</h4>
                              </div>
                              <div className="table-responsive">
                                <table className="table">
                                  <tbody>
                                    <tr>
                                      <td className="cart_total_label">
                                        Cart Subtotal
                                      </td>
                                      <td className="cart_total_amount">
                                        <span className="font-lg fw-900 text-brand">
                                          {FormatGeneralPrice(total)}
                                        </span>
                                      </td>
                                    </tr>
                                    {promoSuccess ? (
                                      <tr>
                                        <td className="cart_total_label">
                                          Discount Applied
                                        </td>
                                        <td className="cart_total_amount">
                                          <span className="font-lg fw-900 text-brand">
                                            {FormatGeneralPrice(promoDiscount)}
                                          </span>
                                        </td>
                                      </tr>
                                    ) : null}

                                    <tr>
                                      <td className="cart_total_label">
                                        Shipping
                                      </td>
                                      <td className="cart_total_amount">
                                        <i className="ti-gift mr-5"></i>
                                        {deliveryCharge !== 0 ? (
                                          FormatGeneralPrice(deliveryCharge)
                                        ) : (
                                          <span>To be advised</span>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="cart_total_label">
                                        Total
                                      </td>
                                      <td className="cart_total_amount">
                                        <strong>
                                          <span className="font-xl fw-900 text-brand">
                                            {FinaliseTotal(
                                              total,
                                              deliveryCharge,
                                              promoDiscount
                                            )}
                                          </span>
                                        </strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <a
                                aria-disabled="true"
                                onClick={(e) => placeOrder()}
                                className="btn"
                              >
                                <i className="fi-rs-box-alt mr-10"></i>{" "}
                                {processingSale ? (
                                  <ButtonLoaderSmall></ButtonLoaderSmall>
                                ) : (
                                  <span>Place order</span>
                                )}
                              </a>
                              <div
                                style={{
                                  paddingTop: "20px",
                                  color: "red",
                                }}
                              >
                                {errorMessage}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12"></div>
                  </div>
                  {/* <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <input
                    type="text"
                    defaultValue={userData.userFirstName}
                    placeholder="First name *"
                    {...register("firstName", {
                      required: "First name is required",
                    })}
                  ></input>
                  {errors.firstName ? (
                    <>
                      {errors.firstName.type === "required" && (
                        <span className="small error">
                          {errors.firstName.message}
                        </span>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Last name *"
                    defaultValue={userData.userSurname}
                    {...register("lastName", {
                      required: "Last name is required",
                    })}
                  ></input>
                  {errors.lastName ? (
                    <>
                      {errors.lastName.type === "required" && (
                        <span className="small error">
                          {errors.lastName.message}
                        </span>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    defaultValue={userData.userHouseNo}
                    placeholder="Company Name"
                    {...register("userHouseNo", {
                      required: "Company name is required",
                    })}
                  ></input>
                  {errors.userHouseNo ? (
                    <>
                      {errors.userHouseNo.type === "required" && (
                        <span className="small error">
                          {errors.userHouseNo.message}
                        </span>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="form-group">
                  <div className="custom_select">
                    <select className="form-control select-active">
                      <option value="">Select an option...</option>
                      {countries &&
                        countries.map((item: any) => (
                          <option
                            value={item.countryID}
                            selected={userData.country === item.countryID}
                          >
                            {item.name}{" "}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Address *"
                    defaultValue={userData.userStreet}
                    {...register("userStreet", {
                      required: "Address field is required",
                    })}
                  ></input>
                  {errors.userStreet ? (
                    <>
                      {errors.userStreet.type === "required" && (
                        <span className="small error">
                          {errors.userStreet.message}
                        </span>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Address line2"
                    defaultValue={userData.userCity}
                    {...register("userCity", {
                      required: "Address field is required",
                    })}
                  ></input>
                  {errors.userCity ? (
                    <>
                      {errors.userCity.type === "required" && (
                        <span className="small error">
                          {errors.userCity.message}
                        </span>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="City / Town *"
                    defaultValue={userData.userCounty}
                    {...register("userCounty", {
                      required: "Address field is required",
                    })}
                  ></input>
                  {errors.userCounty ? (
                    <>
                      {errors.userCounty.type === "required" && (
                        <span className="small error">
                          {errors.userCounty.message}
                        </span>
                      )}
                    </>
                  ) : null}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    defaultValue={userData.userPostcode}
                    {...register("userPostcode", {
                      required: "Postcode / ZIP is required",
                    })}
                    placeholder="Postcode / ZIP *"
                  ></input>
                  {errors.userPostcode ? (
                    <>
                      {errors.userPostcode.type === "required" && (
                        <span className="small error">
                          {errors.userPostcode.message}
                        </span>
                      )}
                    </>
                  ) : null}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Email address *"
                    defaultValue={userData.userEmail}
                    {...register("userEmail", {
                      required: "Email address is required",
                    })}
                  ></input>
                  {errors.userEmail ? (
                    <>
                      {errors.userEmail.type === "required" && (
                        <span className="small error">
                          {errors.userEmail.message}
                        </span>
                      )}
                    </>
                  ) : null}
                </div>

                <div className="mb-20">
                  <h5>Additional information2</h5>
                </div>
                <div className="form-group mb-30">
                  <textarea rows={5} placeholder="Order notes"></textarea>
                </div>

                <button
                  type="submit"
                  className="btn btn-fill-out btn-block mt-30"
                >
                  Place Order
                </button>
              </form> */}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h2>You have no items to check out</h2>
              <hr></hr>
              <br></br> <br></br>
              <Link className="btn " to="/search">
                <i className="fi-rs-box-alt mr-10"></i> Search for products
              </Link>
            </div>
          )}{" "}
        </div>
      </section>
    </main>
  );
}

export default Checkout;
