import Cookie from "js-cookie";
import Base64 from "crypto-js/enc-base64";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import AES from "crypto-js/aes";
import SHA256 from "crypto-js/sha256";
import { getBearer } from "../helpers";
const cookieRetrieve = Cookie.get("token");

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:5011";

export class ApiService {
  public async getOrders(): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      `${API_BASE_URL}/api/Sale/GetAllSalesByComplete`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getPendingOrders(): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      `${API_BASE_URL}/api/Sale/GetAllSalesByPending`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getRelatedProducts(id: number): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Related/GetAllProductsRelated/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return await response.json();
  }

  public async getAllMediaImages(): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/MediaImage/GetAllMediaImages`
    );
    return await response.json();
  }

  public async getAllHomePageImages(): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/HomePageImage/GetAllHomepageImages`
    );
    return await response.json();
  }

  public async getAllPromoCodes(): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch(`${API_BASE_URL}/api/Promo/GetAllPromoCodes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getPromoCodeAdmin(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch(
      `${API_BASE_URL}/api/Promo/GetPromoCodeAdminByID/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getSaleItemsByID(id: number): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      `${API_BASE_URL}/api/SaleItem/GetAllSaleItemsBySaleID/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getDeliveryAddressesByUserID(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch(
      `${API_BASE_URL}/api/DeliveryAddress/GetDeliveryAddressByUserID/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getDeliveryAddressByID(id: number): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      `${API_BASE_URL}/api/DeliveryAddress/GetDeliveryAddressByID/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getOrderByUserID(id: string): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch(
      `${API_BASE_URL}/api/Sale/GetSaleByUserID/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getOrderByID(id: number): Promise<any> {
    let bearerToken = getBearer();
    const response = await fetch(`${API_BASE_URL}/api/Sale/GetSaleByID/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }
  public async getProducts(id: number): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Product/GetAllProductsTest`
    );
    return await response.json();
  }

  public async getPromoCode(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      `${API_BASE_URL}/api/Promo/GetPromoCodeByName/${id}`,
      {
        method: "get",
        headers: {
          Accept: "application/json; charset=utf-8",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getProductCategoryByProductID(id: number): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/ProductCategory/GetProductCategoriesByProductID/${id}`
    );
    return await response.json();
  }

  public async getProductSubCategoryByProductID(id: number): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/ProductSubCategory/GetProductSubCategoriesByProductID/${id}`
    );
    return await response.json();
  }

  public async getProductCategoryByCategoryID(id: number): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/ProductCategory/FindProductCategoriesByCategoryID/${id}`
    );
    return await response.json();
  }

  public async getProductsByKeyword(id: string): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Product/GetAllProductsAdmin/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${cookieRetrieve}`,
        },
      }
    );
    return await response.json();
  }

  public async getOrdersByKeyword(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      `${API_BASE_URL}/api/Sale/GetAllOrdersByKeywordAdmin/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getCustomerByID(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      `${API_BASE_URL}/api/user/GetCustomerByID/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getCustomerByKeyword(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(`${API_BASE_URL}/api/user/GetAllUsers/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getCustomerAll(): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(`${API_BASE_URL}/api/user/GetEveryUser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getUserByID(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(`${API_BASE_URL}/api/user/GetUserByID/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    return await response.json();
  }

  public async getProductByID(id: number): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Product/GetProductByID/${id}`
    );
    return await response.json();
  }

  public async getAllProducts(): Promise<any> {
    const response = await fetch(`${API_BASE_URL}/api/Product/GetAllProducts`);
    return await response.json();
  }

  public async getAllProductsLive(): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Product/GetAllProductsLive`
    );
    return await response.json();
  }

  public async getProductVariantsID(id: number): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/ProductVariant/GetProductVariantByID/${id}
    `
    );
    return await response.json();
  }

  public async getSubProductVariantBySuperID(id: number): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/SubProductVariant/GetAllBySubProductVariantID/${id}
    `
    );
    return await response.json();
  }

  public async getCart(id: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      `${API_BASE_URL}/api/Cart/GetAllCartsByID/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async checkFile(id: number): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      `${API_BASE_URL}/api/user/CheckIfFileExists/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getExchangeRates(): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Exchange/GetAllExchangeRates`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${cookieRetrieve}`,
        },
      }
    );
    return await response.json();
  }
  public async getCountries(): Promise<any> {
    const response = await fetch(`${API_BASE_URL}/api/Country/GetAllCountries`);
    return await response.json();
  }

  public async authUser(userEmail: string, userPassword: string): Promise<any> {
    const response = await fetch(`${API_BASE_URL}/api/user/CreateToken`, {
      method: "post",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        Username: userEmail,
        Password: userPassword,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async createCartItem(
    productID: number,
    productQuantity: number,
    price: number,
    guid: string,
    productVariantID: number,
    subProductVariantID: number
  ): Promise<any> {
    const response = await fetch(`${API_BASE_URL}/api/cart/CreateCartItem`, {
      method: "post",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        productID: productID,
        productQuantity: productQuantity,
        price: price,
        guid: guid,
        productVariantID: productVariantID,
        subProductVariantID: subProductVariantID,
      }),
    });

    return await response.json();
  }

  public async getProductsByCategoryArray(id: string): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Product/GetProductByCategoryArray/" + id`
    );
    return await response.json();
  }

  public async getProductsByCategory(id: string): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Product/GetProductByCategory/" + id`
    );
    return await response.json();
  }

  public async getProductCategoryByCategoryPageNumber(
    id: number,
    pageNumber: number
  ): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/ProductCategory/FindProductsByCategoryID/${id}/${pageNumber}`
    );
    return await response.json();
  }

  public async getProductSubCategoryBySubCategory(
    id: number,
    pageNumber: number
  ): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/ProductSubCategory/FindProductsBySubCategoryID/${id}/${pageNumber}`
    );
    return await response.json();
  }

  public async getProductsByCategoryPagination(
    categoryID: number,
    pageSize: number,
    pageNumber: number
  ): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Product/GetProductByCategoryPageination/${categoryID}/${pageSize}/${pageNumber}`
    );
    return await response.json();
  }

  public async deleteItemFromCart(id: number, guider: string): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      `${API_BASE_URL}/api/Cart/DeleteItemFromCart/${id}/${guider}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async deleteHomepageImage(id: number): Promise<any> {
    let bearerToken = getBearer();

    const response = await fetch(
      `${API_BASE_URL}/api/HomepageImage/DeleteHomeImage/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
      }
    );
    return await response.json();
  }

  public async getProductsBySubGuid(id: string): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Product/GetProductBySubCategoryGuid/${id}`
    );
    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async getProductsBySubID(id: string): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/ProductSubCategory/GetProductsBySubCategory/${id}`
    );
    return await response.json();
  }

  public async getProductsBySearch(
    search: any,
    pageNumber: number
  ): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Product/GetProductBySearch/${search}/${pageNumber}`
    );
    return await response.json();
  }

  public async getCategories(): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Category/GetAllCategories`
    );
    return await response.json();
  }

  public async getCountryByCountryID(id: number): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/Country/GetCountryByCountryID/${id}`
    );
    return await response.json();
  }

  public async getSubCategories(): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/SubCategory/GetAllSubCategories`
    );
    return await response.json();
  }

  public async getSubCategoriesByCategoryID(id: string): Promise<any> {
    const response = await fetch(
      `${API_BASE_URL}/api/SubCategory/GetAllSubCategoriesByID/${id}`
    );
    return await response.json();
  }

  public async addUser(user: any) {
    const response = await fetch(`/api/user`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user }),
    });
    return await response.json();
  }

  public async addUserAccount(user: any) {
    const response = await fetch(`/api/user`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user }),
    });
    return await response.json();
  }

  public async PostData(url: any, data: any) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify(data),
    });
    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async PostDataWithAuth(url: any, data: any) {
    let bearerToken = getBearer();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async PostDeliveryAddress(url: any, data: any) {
    let bearerToken = getBearer();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async PostImageFileData(url: any, data: any) {
    let bearerToken = getBearer();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: data,
    });
    if (response.ok) {
      return await response.json();
    } else {
      return Promise.reject(response);
    }
  }

  public async PostFile(url: any, data: any) {
    let bearerToken = getBearer();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      body: data,
    });
    return response;
  }
}
