import React, { ReactElement, useState, useContext, useEffect } from "react";
import Cookie from "js-cookie";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router";
import { CartContext } from "../../context/cartContext";
import { generateUUID } from "../../helpers";
import { setUser, getUser } from "../../services/auth.service";
import { Link, useLocation } from "react-router-dom";

function Login(): ReactElement {
  const query = new URLSearchParams(useLocation().search);
  const returnUrl = query.get("returnUrl");
  const id = query.get("id");
  const name = query.get("name");

  const [userEmail, setUserEmail] = useState("");
  const [errorResponse, setErrorResponse] = useState(0);

  const [tryingLogin, setTryingLogin] = useState(false);

  const [userPassword, setUserPassword] = useState("");
  let [authAccess] = useState("");
  let [hidden, setHidden] = useState("");

  const { guid, setCartGuid } = useContext(CartContext);

  const navigate = useNavigate();

  const { setUserData } = useContext(AuthContext);

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setHidden("Please enter a valid email address");
      return false;
    } else {
      setHidden("");
      return true;
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (validateEmail(userEmail) === true) {
      setTryingLogin(true);
      let errorCode = 0;
      fetch("api/user/CreateToken", {
        method: "post",
        headers: {
          Accept: "application/json; charset=utf-8",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          Username: userEmail,
          Password: userPassword,
        }),
      })
        .then(function (response) {
          // Will show you the status
          if (!response.ok) {
            if (response.status === 401) {
              setHidden("There was a problem with those details.");
            } else {
              setHidden("There was a problem logging in");
            }
          }
          return response.json();
        })
        .then((result) => {
          Cookie.set("token", result.token);

          setUser(result.userID, result.token);

          // const cookieVal = Cookie.get("cart");
          // if (cookieVal !== undefined) {
          //   console.log("cookie");
          //   console.log("cookieVal " + cookieVal);
          //   setCartGuid(cookieVal);
          // } else {
          //   console.log("no cookie");
          const newGuid = generateUUID();
          Cookie.set("cart", newGuid);
          setCartGuid(newGuid);

          const user = getUser();

          setUserData(user);
          setTryingLogin(false);

          if (returnUrl) {
            navigate(`/product?id=${id}&name=${name}`);
          } else {
            navigate("/Dashboard");
          }
        })
        .catch((error) => {
          setTryingLogin(false);
        });
    }
  };

  useEffect(() => {}, []);

  return (
    <main className="main">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/">Home</Link>
            <span></span> Login / Register
          </div>
        </div>
      </div>
      <section className="pt-50 pb-50">
        <div className="container ">
          <div className="row">
            <div className="col-lg-12 m-auto d-flex align-items-center justify-content-center">
              <div className="row">
                <div className="col-lg-12 m-auto d-flex align-items-center justify-content-center">
                  <div className="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                    <div className="padding_eight_all bg-white">
                      <div className="heading_s1">
                        <h3 className="pb-30">Login</h3>
                        {hidden !== "" ? (
                          <h4 className="pb-30 error">{hidden}</h4>
                        ) : null}
                      </div>
                      <form name="loginForm" onSubmit={onSubmit}>
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            placeholder="Your Email"
                            onChange={(e) => setUserEmail(e.target.value)}
                            maxLength={50}
                          ></input>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={(e) => setUserPassword(e.target.value)}
                            maxLength={30}
                          ></input>
                        </div>
                        <div className="login_footer form-group">
                          <div className="chek-form">
                            <div className="custome-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="checkbox"
                                id="exampleCheckbox1"
                                value=""
                              ></input>
                              {/* <label className="form-check-label">
                                <span>Remember me</span>
                              </label> */}
                            </div>
                          </div>

                          <Link className="text-muted" to="/Forgot-Password">
                            Forgot password
                          </Link>
                        </div>
                        <div className="form-group">
                          {tryingLogin ? (
                            <p>
                              <b>Logging you in... Please wait</b>
                            </p>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-fill-out btn-block hover-up"
                              name="login"
                            >
                              Log in
                            </button>
                          )}
                        </div>
                        <div className="divider-text-center mt-15 mb-15">
                          <span> or</span>
                        </div>

                        <div className="text-muted text-center">
                          Don't have an account?{" "}
                          <Link to="/Create-Account">Create one now</Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Login;
