import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { currencySelector, FormatDate } from "../../../helpers";
import { ApiService } from "../../../services/api.service";
function RecentOrders(): ReactElement {
  const [orders, setOrders] = useState([]);

  const apiService = new ApiService();

  const navigate = useNavigate();

  function handleClick(item: any) {
    navigate("/Administration/OrderView?id=" + item);
  }

  const fetchAllOrders = async () => {
    const response = await apiService.getOrders();
    setOrders(response);
  };

  useEffect(() => {
    fetchAllOrders();
  }, []);

  return (
    <div className="container p-5 mb-4 bg-light rounded-3">
      <h3>Recent Orders</h3>
      <hr></hr>
      <div className="table-responsive">
        <table className="table table-sm admin">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">User</th>
              {/* <th style={{ width: "40%" }} scope="col">
                Address
              </th> */}
              <th scope="col" style={{ width: "20%" }}>
                Date
              </th>
              <th scope="col" style={{ width: "20%" }}>
                Sub Total
              </th>
              {/* <th scope="col">Discount</th>
              <th scope="col">PO No.</th> */}
            </tr>
          </thead>
          <tbody>
            {orders &&
              orders.slice(0, 10).map((item: any) => (
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClick(item.saleID)}
                >
                  <td>{"MA00" + item.saleID}</td>
                  <td>{item.userFirstname + " " + item.userSurname}</td>
                  {/* <td>{item.deliveryAddress}</td> */}
                  <td>{FormatDate(item.saleDate)}</td>
                  <td>
                    {currencySelector(item.currencyID, item.saleGBPTotal)}
                  </td>
                  {/* <td>
                    {" "}
                    {currencySelector(item.currencyID, item.discountApplied)}
                  </td>
                  <td>{item.poNumber}</td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default RecentOrders;
