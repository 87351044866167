import React, { ReactElement, useState, useEffect } from "react";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { useLocation, useNavigate } from "react-router";
import ButtonLoaderSmall from "../../ButtonLoaderSmall";

import { ApiService } from "../../../services/api.service";

function HomePageImage(): ReactElement {
  const location = useLocation();

  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [fileExists, setFileExists] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");
  const [uploadResponse, setUploadResponse] = useState("");

  if (location.pathname.includes("Administration")) {
    var el = document.getElementById("MAheader");
    if (el !== null) {
      el.style.display = "none";
    }
  }

  const apiService = new ApiService();

  const [mediaImages, setMediaImages] = useState<any | null>(null);

  const fetchExchange = async () => {
    const response = await apiService.getAllHomePageImages();
    setMediaImages(response);
  };

  const onFileChange = (e: any) => {
    // Update the state
    setSelectedFile(e.target.files[0]);
  };

  const handleInputChange = (index: any, event: any, homepageUrl: any) => {
    const values: any = [...mediaImages];

    const updatedValue = event.target.name;

    //values[index][updatedValue] = event.target.value;
    values[index]["homepageUrl"] = event.target.value;
    setMediaImages(values);
  };

  const onFileUpload = (e: any) => {
    e.preventDefault();
    setUploading(true);
    // Create an object of formData
    const formData = new FormData();

    var data = {
      Name: "1",
      Image: selectedFile,
    };

    // // Update the formData object
    // formData.append("Image", selectedFile);
    // formData.append("Name", "1");

    PostData(data);
  };

  const PostData = async (data: any) => {
    setUploading(true);
    const response = await apiService.PostFile(
      "api/HomepageImage/UploadHomeImage",
      data
    );

    if (response.ok) {
      setUploadResponse("Your Home Page Image has been uploaded");
      setUploadComplete(true);
    } else {
      setUploadResponse("Your Home Page Image has not been uploaded");
    }
    setUploading(false);
  };

  const PostHomePageImageData = async () => {
    const response = await apiService.PostData(
      "api/HomepageImage/SaveHomePageImage",
      mediaImages
    );
  };

  const RemoveHomePageImageData = async (e: number) => {
    const response = await apiService.deleteHomepageImage(e);
    fetchExchange();
  };

  useEffect(() => {
    fetchExchange();
  }, []);
  return (
    <div>
      <AdminHeader></AdminHeader>

      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Media Images</h1>
              <div className="clearfix"></div>
            </div>
            <div className="row">
              <div className="col-md-4 text-left border-bottom pb-2 mb-3">
                <h4>UK Images</h4>
                <hr></hr>
                <span>
                  <input
                    className="inputfile"
                    type="file"
                    onChange={onFileChange}
                  />
                  <button className="btn btn-primary" onClick={onFileUpload}>
                    {uploading ? (
                      <ButtonLoaderSmall></ButtonLoaderSmall>
                    ) : (
                      <span>Upload A Home Image</span>
                    )}
                  </button>
                </span>
                <hr></hr>
                {mediaImages &&
                  mediaImages.map((item: any, index: number) =>
                    item.countryID === 1 ? (
                      <div>
                        <img
                          src={
                            window.location.origin +
                            `/Admin/homepageImages/` +
                            item.homepageImagePath
                          }
                          className="img-thumbnail"
                          style={{ width: "100%", marginTop: "-1px" }}
                          alt="No"
                        ></img>
                        <input
                          type="text"
                          defaultValue={item.homepageUrl}
                          name="homepageUrl"
                          onChange={(event: any) =>
                            handleInputChange(
                              index,
                              event,
                              item.homepageImageID
                            )
                          }
                        />
                        <button
                          onClick={(e) => PostHomePageImageData()}
                          className="btn btn-success btn-sm"
                        >
                          Add URL
                        </button>
                        <button
                          onClick={(e) =>
                            RemoveHomePageImageData(item.homepageImageID)
                          }
                          className="btn btn-danger btn-sm"
                        >
                          Remove
                        </button>
                        <hr></hr>
                      </div>
                    ) : null
                  )}
              </div>
              <div className="col-md-4 text-left border-bottom pb-2 mb-3">
                <h4>US Images</h4>
                <hr></hr>

                <span>
                  <input
                    className="inputfile"
                    type="file"
                    onChange={onFileChange}
                  />
                  <button className="btn btn-primary" onClick={onFileUpload}>
                    {uploading ? (
                      <ButtonLoaderSmall></ButtonLoaderSmall>
                    ) : (
                      <span>Upload A Home Image</span>
                    )}
                  </button>
                </span>

                <hr></hr>
                {mediaImages &&
                  mediaImages.map((item: any, index: number) =>
                    item.countryID === 2 ? (
                      <div>
                        <img
                          src={
                            window.location.origin +
                            `/Admin/homepageImages/` +
                            item.homepageImagePath
                          }
                          className="img-thumbnail"
                          style={{ width: "100%", marginTop: "-1px" }}
                          alt="No"
                        ></img>
                        <input
                          type="text"
                          defaultValue={item.homepageUrl}
                          name="homepageUrl"
                          onChange={(event: any) =>
                            handleInputChange(index, event, item.exchangeID)
                          }
                        />
                        <button className="btn btn-success btn-sm">
                          Add URL
                        </button>
                        <button
                          onClick={(e) =>
                            RemoveHomePageImageData(item.homepageImageID)
                          }
                          className="btn btn-danger btn-sm"
                        >
                          Remove
                        </button>
                        <hr></hr>
                      </div>
                    ) : null
                  )}
              </div>
              <div className="col-md-4 text-left border-bottom pb-2 mb-3">
                <h4>Default/Generic Images</h4>

                <hr></hr>
                <span>
                  <input
                    className="inputfile"
                    type="file"
                    onChange={onFileChange}
                  />
                  <button className="btn btn-primary" onClick={onFileUpload}>
                    {uploading ? (
                      <ButtonLoaderSmall></ButtonLoaderSmall>
                    ) : (
                      <span>Upload A Home Image</span>
                    )}
                  </button>
                </span>
                <hr></hr>
                {mediaImages &&
                  mediaImages.map((item: any, index: number) =>
                    item.countryID === 3 ? (
                      <div>
                        <img
                          src={
                            window.location.origin +
                            `/Admin/homepageImages/` +
                            item.homepageImagePath
                          }
                          className="img-thumbnail"
                          style={{ width: "100%", marginTop: "-1px" }}
                          alt="No"
                        ></img>
                        <input
                          type="text"
                          defaultValue={item.homepageUrl}
                          name="homepageUrl"
                          onChange={(event: any) =>
                            handleInputChange(index, event, item.exchangeID)
                          }
                        />
                        <button className="btn btn-success btn-sm">
                          Add URL
                        </button>
                        <button
                          onClick={(e) =>
                            RemoveHomePageImageData(item.homepageImageID)
                          }
                          className="btn btn-danger btn-sm"
                        >
                          Remove
                        </button>
                        <hr></hr>
                      </div>
                    ) : null
                  )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
export default HomePageImage;
