import React, { useState, createContext, useEffect } from "react";
import { ApiService } from "../services/api.service";
interface CountryProviderProps {
  children: React.ReactNode;
}

export const CountryContext = createContext({
  countryData: [],
  setCountryData: (loggedIn: []) => {},
});

export const CountryProvider = ({ children }: CountryProviderProps) => {
  const [countryData, setCountryData] = useState([]);

  const apiService = new ApiService();
  useEffect(() => {
    const fetchCountries = async () => {
      const response = await apiService.getCountries();

      // let sortedProducts = response.sort(
      //   (a: any, b: any) => parseFloat(a.catOrder) - parseFloat(b.catOrder)
      // );

      setCountryData(response);
    };
    fetchCountries();
  }, []);

  return (
    <CountryContext.Provider value={{ countryData, setCountryData }}>
      {children}
    </CountryContext.Provider>
  );
};
