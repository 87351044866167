import React, { useState, createContext, useEffect, useContext } from "react";
import { ApiService } from "../services/api.service";
import Cookie from "js-cookie";
import { UserModel } from "../types/user";
import { generateUUID } from "../helpers";
import { CartContext } from "../context/cartContext";
import { getUser, clearUser, buildUser } from "../services/auth.service";

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext({} as any);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  // const [userData, setUserData] = useState({
  //   // userGuid: "",
  //   // userFirstName: "",
  //   // refToken: "",
  //   // currency: "",
  //   // userSurname: "",
  //   // userEmail: "",
  //   // userStatus: 0,
  //   // Country: 0,
  //   // account: 0,
  //   // partner: 0,
  //   // priceClassID: 0,
  //   // adminContact: 0,
  //   // userHouseNo: "",
  //   // userStreet: "",
  //   // userPostcode: "",
  //   // userCounty: "",
  //   // userCity: "",
  //   // userSubscribe: "",
  //   displayName: "",
  // });

  const [userData, setUserData] = useState({} as any | null);

  useState<UserModel[] | null | string>();

  const apiService = new ApiService();

  useEffect(() => {
    const authUser = async () => {
      const cookieVal = Cookie.get("token");
      if (cookieVal !== undefined) {
        let user = buildUser(cookieVal);
        if (user === undefined) {
        } else {
          setUserData(user);
        }
      }
      // setUserData({ userGuid: "" });
    };
    authUser();
  }, []);

  return (
    <AuthContext.Provider value={{ userData, setUserData }}>
      {children}
    </AuthContext.Provider>
  );
};
