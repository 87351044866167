import React, {
  ReactElement,
  useState,
  useEffect,
  useContext,
  useRef,
  MutableRefObject,
} from "react";
import { useLocation, useNavigate } from "react-router";
import { LoginContext } from "../../../context/categoryContext";
import { ApiService } from "../../../services/api.service";
import AdminHeader from "../shared/header";
import AdminNav from "../shared/nav";
import { useForm } from "react-hook-form";
import { SubCatContext } from "../../../context/subCategoryContext";
import Loader from "../../Loader";
import { Link } from "react-router-dom";

interface Category {
  categoryID: number;
  categoryName: string;
  categoryGuid: string;
  show: number;
  delivery: number;
  deliveryadd: number;
  catorder: number;
  homepageShow: number;
}

function AddCategory(): ReactElement {
  const apiService = new ApiService();
  const navigate = useNavigate();

  const [isError, setIsError] = useState(0);

  const [cats, setCats] = useState<Category | null>({
    categoryID: 0,
    categoryName: "",
    categoryGuid: "",
    show: 3,
    delivery: 0,
    deliveryadd: 0,
    catorder: 0,
    homepageShow: 0,
  });

  const handleInputChange = (event: any) => {
    const values: any = { ...cats };

    if (cats === undefined) {
      values.push({
        categoryID: 0,
        categoryName: "",
        categoryGuid: "",
        show: 3,
        delivery: 0,
        deliveryadd: 0,
        catorder: 0,
        homepageShow: 0,
      });
    }

    const updatedValue = event.target.name;
    values[updatedValue] = event.target.value;
    setCats(values);
  };

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = () => {
    PostCatData(cats);
  };

  const PostCatData = async (data: any) => {
    const response = await apiService.PostData(
      "api/Category/SaveCategory",
      data
    );
    if (response === 1) {
      setIsError(1);
      setTimeout(() => setIsError(0), 3000);
      navigate("/Administration/Categories");
    } else {
      setIsError(2);
    }
  };

  useEffect(() => {
    reset();
  }, []);

  return (
    <div>
      <AdminHeader></AdminHeader>
      <div className="container-fluid">
        <div className="row">
          <AdminNav></AdminNav>

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Add Category:</h1>
            </div>
            <div className="table-responsive">
              <form onSubmit={handleSubmit(onSubmit)}>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Category</th>
                      <th scope="col">Show</th>
                      <th scope="col">Main Area</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="categoryName"
                          onChange={(event: any) => handleInputChange(event)}
                        />
                      </td>
                      <td>
                        <div className="form-check">
                          <select
                            className="form-select"
                            id="country"
                            name="show"
                            onChange={(event: any) => handleInputChange(event)}
                            defaultValue={"3"}
                          >
                            <option value="1">Live</option>
                            <option value="3">Archived</option>
                          </select>
                        </div>
                      </td>

                      <td>
                        <div className="form-check">
                          <select
                            className="form-select"
                            id="country"
                            name="homepageShow"
                            onChange={(event: any) => handleInputChange(event)}
                            defaultValue={"0"}
                          >
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  type="submit"
                  className="btn btn-fill-out btn-block hover-up"
                  name="login"
                >
                  Save Category
                </button>
                <span
                  className={`badge badge-pill badge ${
                    isError === 2 ? "red" : isError === 1 ? "green" : ""
                  } `}
                >
                  {isError === 2 ? "Error" : isError === 1 ? "Saved" : ""}
                </span>
              </form>
            </div>

            <hr></hr>
          </main>
        </div>
      </div>
    </div>
  );
}
export default AddCategory;
