import React, { ReactElement, Suspense, useContext } from "react";
import styled from "@emotion/styled";
import { Card } from "react-bootstrap";
import { colours } from "../../styles/colours";
import { motion } from "framer-motion";
import { FormatMoney, Truncate } from "../../helpers";
import { AuthContext } from "../../context/authContext";
import { SavedSearchContext } from "../../context/savedSearchContext";
import LazyLoad from "react-lazyload";

interface ProductGridItemProps {
  name: string;
  price?: number;
  description?: string;
  category?: string;
  productIntro?: string;
  flash?: string;
  award: number;
  topCategory: string;
  useCloudImage: number;
}

const S = {
  ProductBox: styled.div`
    font-size: 16px;
    height: 250px;
    max-width: 400px;
    min-width: 200px;
    border: #ccc 1px solid;
    img {
      width: 100%;
    }
    padding: 5px;
  `,
  ProductTitle: styled.div`
    font-size: 16px;
    padding: 5px;
    font-weight: 400;
    line-height: 16px;
    display: inline;
  `,
  ProductDescription: styled.div`
    font-size: 11px;
    padding: 5px;
    font-weight: 600;
  `,
  ProductPrice: styled.div`
    font-size: 18px;
    font-weight: 900;
    padding: 5px;
    text-align: right;
    color: ${colours.MARKANDY_RED};
  `,
  ProductImage: styled.div`
    margin-bottom: 10px;
  `,
  CategoryName: styled.div`
    font-size: 13px;
    font-weight: 200;
  `,
  Card: styled(Card)`
    box-shadow: 0 2px 4px #dadada;
    border-radius: 5px;
    min-height: 200px;
    margin-bottom: 20px;
  `,
};

function ProductGridItem({
  name,
  price,
  description,
  category,
  productIntro,
  flash,
  award,
  topCategory,
  useCloudImage,
}: ProductGridItemProps): ReactElement {
  const userData = useContext(AuthContext);

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Suspense fallback={<div>Loading</div>}>
          <S.Card>
            <div className="card-body">
              <S.ProductImage>
                {flash ? (
                  <div
                    style={{
                      position: "absolute",
                      height: "50px",
                      width: "auto",
                      backgroundColor: `${colours.BLACK}`,
                      color: "#fff",
                      top: "25px",
                      right: "0px",
                      padding: "15px",
                      fontSize: "15px",
                      fontWeight: "600",
                      textAlign: "right",
                      borderBottom: `${colours.MARKANDY_RED} 3px solid`,
                    }}
                  >
                    <p>{flash}</p>
                  </div>
                ) : null}
                {useCloudImage === 1 ? (
                  <img
                    className="card-img-top"
                    src={`https://res.cloudinary.com/cloud2mad/image/upload/c_scale,l_jluhalsdbrro7zqthzv3,w_421/v1678617512/${name}.jpg`}
                    style={{
                      maxHeight: "70vw",
                      minHeight: "15vw",
                      objectFit: "cover",
                      width: "100%",
                    }}
                    alt=""
                  ></img>
                ) : (
                  <img
                    className="card-img-top"
                    src={
                      window.location.origin + `/Admin/Catalogue/${name}.jpg`
                    }
                    style={{
                      maxHeight: "70vw",
                      minHeight: "15vw",
                      objectFit: "cover",
                      width: "100%",
                    }}
                    alt=""
                  ></img>
                )}
              </S.ProductImage>
              <S.ProductTitle className="card-title"> {name}</S.ProductTitle>
              <S.ProductDescription>
                {Truncate(description!)}
              </S.ProductDescription>

              {
                userData.userData.adminContact === 2 && award === 3 ? (
                  <p></p>
                ) : (userData.userData.adminContact === 2 && award) === 1 ? (
                  <p></p>
                ) : null // <S.ProductPrice> {FormatMoney(price!)}</S.ProductPrice>
              }

              <S.CategoryName>
                <h5>{category}</h5>
              </S.CategoryName>
            </div>
          </S.Card>
        </Suspense>
      </motion.div>
    </div>
  );
}

export default ProductGridItem;
